import React, { FC } from "react";
import { Edit, EditProps, SimpleForm } from "react-admin";
import { SensorFormContent } from "./SensorFormContent";

export const SensorEdit: FC<EditProps> = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <SensorFormContent />
    </SimpleForm>
  </Edit>
);
