import { Stack } from "@mui/material";
import React from "react";
import { TextInput, NumberInput, AutocompleteInput, required } from "react-admin";

export const MicrophoneModelFormContent = () => (
  <Stack width={{ xs: "100%", md: 300 }}>
    <TextInput source="name" validate={required()} />
    <NumberInput source="sensitivity" validate={required()} />
    <AutocompleteInput
      source="type"
      validate={required()}
      choices={[
        { id: "DIGITAL", name: "Digital" },
        { id: "ANALOG", name: "Analog" },
      ]}
    />
  </Stack>
);
