import React from "react";
import { Create, SimpleForm, TextInput, required, CreateProps } from "react-admin";

export const ClientCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
