import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={144.955}
      height={135.461}
      viewBox="0 0 38.353 35.841"
      {...props}
    >
      <defs>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-98.467 623.626) scale(100.541)"
          spreadMethod="pad"
          id="prefix__b"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-91.6 623.626) scale(86.8078)"
          spreadMethod="pad"
          id="prefix__c"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-84.734 623.626) scale(73.0743)"
          spreadMethod="pad"
          id="prefix__d"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-77.867 623.626) scale(59.3409)"
          spreadMethod="pad"
          id="prefix__e"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-71 623.626) scale(45.6074)"
          spreadMethod="pad"
          id="prefix__f"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-64.134 623.626) scale(31.874)"
          spreadMethod="pad"
          id="prefix__g"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-57.267 623.626) scale(18.1405)"
          spreadMethod="pad"
          id="prefix__h"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-50.4 623.626) scale(4.40706)"
          spreadMethod="pad"
          id="prefix__i"
        >
          <stop offset={0} stopColor="#efeeee" />
          <stop offset={0.026} stopColor="#efeeee" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__j">
          <path d="M0 623.626h870.236V0H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__k">
          <path d="M637.082 295.433h22.139v-2.138h-22.139z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__a">
          <path d="M679.946 356.851l-1.428-.004a9.442 9.442 0 01-8.927-6.428l-6.799-18.579-7.216 16.812a7.794 7.794 0 01-7.401 5.291h-.114a7.797 7.797 0 01-7.389-5.36l-6.985-16.778-6.847 18.504a9.443 9.443 0 01-8.96 6.4l-8.258-.019 18.947-52.945 4.879.01a9.446 9.446 0 018.823 6.139l5.95 15.928 6.04-15.908a9.44 9.44 0 018.849-6.09l4.982.011 18.588 53.031z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        transform="matrix(.35278 0 0 -.35278 -209.896 126.894)"
      >
        <path
          d="M0 0c0 26.618-21.578 48.197-48.196 48.197S-96.394 26.618-96.394 0c0-26.619 21.58-48.197 48.198-48.197C-21.578-48.197 0-26.619 0 0z"
          fill="none"
          stroke="url(#prefix__b)"
          strokeWidth={4.148}
          strokeMiterlimit={10}
          transform="translate(696.449 324.485)"
        />
        <path
          d="M0 0c0 22.902-18.565 41.468-41.468 41.468-22.902 0-41.469-18.566-41.469-41.468 0-22.902 18.567-41.468 41.469-41.468C-18.565-41.468 0-22.902 0 0z"
          fill="none"
          stroke="url(#prefix__c)"
          strokeWidth={3.871}
          strokeMiterlimit={10}
          transform="translate(689.72 324.485)"
        />
        <path
          d="M0 0c0 19.186-15.553 34.74-34.739 34.74-19.187 0-34.74-15.554-34.74-34.74 0-19.187 15.553-34.74 34.74-34.74C-15.553-34.74 0-19.187 0 0z"
          fill="none"
          stroke="url(#prefix__d)"
          strokeWidth={3.595}
          strokeMiterlimit={10}
          transform="translate(682.992 324.485)"
        />
        <path
          d="M0 0c0 15.47-12.541 28.011-28.011 28.011-15.47 0-28.011-12.541-28.011-28.011 0-15.47 12.541-28.011 28.011-28.011C-12.541-28.011 0-15.47 0 0z"
          fill="none"
          stroke="url(#prefix__e)"
          strokeWidth={3.318}
          strokeMiterlimit={10}
          transform="translate(676.263 324.485)"
        />
        <path
          d="M0 0c0 11.754-9.528 21.283-21.282 21.283S-42.565 11.754-42.565 0c0-11.754 9.529-21.283 21.283-21.283C-9.528-21.283 0-11.754 0 0z"
          fill="none"
          stroke="url(#prefix__f)"
          strokeWidth={3.042}
          strokeMiterlimit={10}
          transform="translate(669.534 324.485)"
        />
        <path
          d="M0 0c0 8.038-6.516 14.554-14.554 14.554S-29.108 8.038-29.108 0s6.516-14.554 14.554-14.554S0-8.038 0 0z"
          fill="none"
          stroke="url(#prefix__g)"
          strokeWidth={2.765}
          strokeMiterlimit={10}
          transform="translate(662.806 324.485)"
        />
        <path
          d="M0 0a7.825 7.825 0 11-15.651 0A7.826 7.826 0 010 0z"
          fill="none"
          stroke="url(#prefix__h)"
          strokeWidth={2.489}
          strokeMiterlimit={10}
          transform="translate(656.077 324.485)"
        />
        <path
          d="M0 0a1.097 1.097 0 11-2.194 0A1.097 1.097 0 010 0z"
          fill="none"
          stroke="url(#prefix__i)"
          strokeWidth={2.213}
          strokeMiterlimit={10}
          transform="translate(649.349 324.485)"
        />
      </g>
      <g
        clipPath="url(#prefix__j)"
        transform="matrix(.35278 0 0 -.35278 -209.896 126.894)"
      >
        <g clipPath="url(#prefix__k)" opacity={0.4}>
          <path
            d="M659.221 294.364c0 .59-4.956 1.069-11.069 1.069-6.115 0-11.07-.48-11.07-1.07 0-.59 4.955-1.068 11.07-1.068 6.113 0 11.07.479 11.07 1.069"
            fill="#fff"
          />
        </g>
        <path
          d="M604.458 266.76h-1.493l-5.15 14.779h1.615l4.297-12.615 3.93 12.615h1.433l3.991-12.615c1.402 4.205 2.864 8.41 4.297 12.615h1.584l-5.119-14.78h-1.524l-3.93 12.525zM634.805 274.256c-.396.06-1.736.274-3.748.274-1.858 0-5.393-.426-5.393-3.382 0-2.712 2.833-3.23 4.662-3.23 1.798 0 4.48.487 4.48 3.169zm1.493 1.34v-4.51c0-4.021-4.113-4.54-5.972-4.54-1.919 0-6.155.61-6.155 4.602 0 4.6 5.698 4.784 7.07 4.784 1.98 0 3.046-.214 3.534-.305 0 3.108-1.524 4.662-4.875 4.662-1.92 0-3.505-.457-4.205-.67v1.463c1.096.365 2.62.7 4.296.7 4.388 0 6.307-2.163 6.307-6.185M653.483 281.538h1.615l-5.972-14.779h-1.524c-2.012 4.906-3.962 9.842-5.973 14.78h1.616l5.119-12.89zM661.648 274.622h9.842c0 .73-.183 5.729-4.905 5.729-3.201 0-4.785-2.133-4.937-5.73m11.396-.548c0-.67-.03-.762-.03-.792h-11.366c.03-.518.122-.945.274-1.31.426-2.865 2.438-3.961 5.302-3.961 1.798 0 3.596.365 4.815 1.035v-1.553c-1.371-.641-3.261-.945-4.937-.945-3.351 0-7.01 1.432-7.01 7.313 0 2.346.733 7.923 6.583 7.923 6.308 0 6.369-6.734 6.369-7.71M680.02 287.237h1.523V266.76h-1.523z"
          fill="#fff"
        />
        <g>
          <path
            d="M699.246 281.538h1.615l-6.185-16.546c-1.219-3.26-2.987-4.053-4.51-4.053-.549 0-1.097.152-1.4.274v1.402c.304-.152.882-.274 1.249-.274 2.315 0 2.894 2.62 3.778 4.418l-5.76 14.78h1.647c1.584-4.266 3.229-8.532 4.844-12.798z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
