import React from "react";

import { Create, SimpleForm, CreateProps } from "react-admin";

import { parse } from "query-string";
import { useLocation, useNavigate } from "react-router";
import RouteFormContent from "./RouteForm";

export const RouteCreate = (props: CreateProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { campaign_id: campaign_id_string } = parse(location.search);
  let campaign_id = undefined;
  if (typeof campaign_id_string === "string") {
    campaign_id = parseInt(campaign_id_string, 10);
  }

  const onSuccess = () => {
    navigate(-1);
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <RouteFormContent campaign_id={campaign_id} />
      </SimpleForm>
    </Create>
  );
};
