import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import {
  ButtonProps,
  HttpError,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

const ConfigVersionDeployButton = (props: ButtonProps) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [create] = useCreate(undefined, undefined, {
    onError: (error: unknown) => {
      notify((error as HttpError)?.message, { type: "warning", undoable: false });
    },
    onSuccess: () => {
      notify("Deployment request successful.", { type: "success", undoable: false });
      refresh();
    },
  });

  const deployVersion = (config_version_id: number) => {
    create("magneto_config_deployments", {
      data: { config_version_id: config_version_id },
    });
  };

  return (
    <Tooltip title="Deploy">
      <IconButton onClick={() => deployVersion(Number(record.id))} {...props}>
        <RocketLaunchIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default ConfigVersionDeployButton;
