import React from "react";
import { Edit } from "react-admin";
import { AreaEditForm } from "./AreaEditForm";

export const AreaEdit = () => {
  return (
    <Edit>
      <AreaEditForm endpoint="areas" reference="sites" />
    </Edit>
  );
};
