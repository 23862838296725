import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ListViewProps,
} from "react-admin";

const microphoneModelFilters = [
  <TextInput source="search_name" label="name" alwaysOn />,
];

export const MicrophoneModelList = (props: ListViewProps) => (
  <List filters={microphoneModelFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <EditButton />
    </Datagrid>
  </List>
);
