import React, { useEffect, useState } from "react";
import {
  useRecordContext,
  useNotify,
  useRedirect,
  useUpdate,
  HttpError,
} from "ra-core";
import { useParams } from "react-router-dom";
import { LocationForm } from "./LocationForm";
import { FieldValues } from "react-hook-form";

export const LocationEditForm = () => {
  /**  Since Location can only be linked to one of Campaign,
   * Area or Site, 2 out of 3 field will be left empty.
   * The way React Admin handle this is by sending an empty
   * string.
   * Since our API expect null value for non linked field
   * we need to convert those empty string to avoid bugs.
   */
  // FIXME: fix the form so that it is not required
  const convertEmptyValue = (v: string) => (v.length === 0 ? null : v);

  const { id } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();
  const onSubmit = (data: FieldValues) => {
    data.campaign_id = convertEmptyValue(data.campaign_id);
    data.area_id = convertEmptyValue(data.area_id);
    data.site_id = convertEmptyValue(data.site_id);

    if (!data.coordinates.includes("gps")) {
      data.position = null;
    }

    if (!data.coordinates.includes("cartesian")) {
      data.cartesian_coordinates = null;
    }

    update(
      "locations",
      { id, data },
      {
        onSuccess: () => {
          redirect("/locations");
          notify("Location updated", { type: "info", undoable: true });
        },
        onError: (error: unknown) => {
          notify((error as HttpError)?.message, { type: "warning", undoable: false });
        },
      }
    );
  };

  const record = useRecordContext();
  const [linkedTo, setLinkedTo] = useState("");

  useEffect(() => {
    if (record.campaign_id) {
      setLinkedTo("campaign");
    } else if (record.area_id) {
      setLinkedTo("area");
    } else if (record.site_id) {
      setLinkedTo("site");
    }
  }, [record.campaign_id, record.area_id, record.site_id]);

  const coordinates = [];
  if (record.position !== null) {
    coordinates.push("gps");
  }

  if (record.cartesian_coordinates !== null) {
    coordinates.push("cartesian");
  }
  record.coordinates = coordinates;

  return (
    <LocationForm
      record={record}
      onSubmit={onSubmit}
      formType="edit"
      linkedTo={linkedTo}
      setLinkedTo={setLinkedTo}
    />
  );
};
