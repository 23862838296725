import React from "react";
import { useNotify, useRedirect, useCreate, HttpError } from "ra-core";
import { PolygonForm } from "./PolygonForm";
import { FieldValues } from "react-hook-form";

interface CreateFormProps {
  endpoint: string;
  reference: string;
}

export const AreaCreateForm = (props: CreateFormProps) => {
  const { endpoint, reference } = props;
  const basePath = `/${endpoint}`;
  const notify = useNotify();
  const redirect = useRedirect();
  const [create] = useCreate();
  const onSubmit = (data: FieldValues) => {
    data.polygon.coordinates[0] = data.polygon.coordinates[0].map(
      (item: Array<number>) => [item[0], item[1]]
    );
    create(
      endpoint,
      { data },
      {
        onSuccess: () => {
          notify("Element created", { type: "info", undoable: true });
          redirect(basePath);
        },
        onError: (error: unknown) => {
          if ((error as HttpError)?.message.includes("Polygon must be convex")) {
            data.polygon.coordinates[0].pop();
          }
          notify((error as HttpError)?.message, { type: "warning", undoable: false });
        },
      }
    );
  };

  return <PolygonForm reference={reference} onSubmit={onSubmit} formType="create" />;
};
