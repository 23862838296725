import React from "react";
import { Edit, SimpleForm, TextInput, required, EditProps } from "react-admin";

export const ClientEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" validate={required()} />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);
