import React, { useState } from "react";
import { LocationForm } from "./LocationForm";
import {
  useNotify,
  useRedirect,
  useCreate,
  useRecordContext,
  HttpError,
} from "ra-core";
import { FieldValues } from "react-hook-form";

export const LocationCreateForm = () => {
  /**  Since Location can only be linked to one of Campaign,
   * Area or Site, 2 out of 3 field will be left empty.
   * The way React Admin handle this is by sending an empty
   * string.
   * Since our API expect null value for non linked field
   * we need to convert those empty string to avoid bugs.
   */
  // FIXME: change the form so that this is not required
  const convertEmptyValue = (v: string) => (v.length === 0 ? null : v);

  const notify = useNotify();
  const redirect = useRedirect();
  const [create] = useCreate();
  const onSubmit = (data: FieldValues) => {
    data.campaign_id = convertEmptyValue(data.campaign_id);
    data.area_id = convertEmptyValue(data.area_id);
    data.site_id = convertEmptyValue(data.site_id);

    if (!data.coordinates.includes("gps")) {
      data.position = null;
    }

    if (!data.coordinates.includes("cartesian")) {
      data.cartesian_coordinates = null;
    }

    create(
      "locations",
      { data },
      {
        onSuccess: () => {
          redirect("/locations");
          notify("Location created", { type: "info", undoable: true });
        },
        onError: (error: unknown) => {
          notify((error as HttpError)?.message, { type: "warning", undoable: false });
        },
      }
    );
  };

  const [linkedTo, setLinkedTo] = useState("");

  const record = useRecordContext();
  record.coordinates = [];

  return (
    <LocationForm
      record={record}
      onSubmit={onSubmit}
      formType="create"
      linkedTo={linkedTo}
      setLinkedTo={setLinkedTo}
    />
  );
};
