import React from "react";
import { Admin as ReactAdmin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import jsonServerProvider, { httpClient } from "../dataProvider";
import AppsIcon from "@mui/icons-material/Apps";
import UserIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderIcon from "@mui/icons-material/Folder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SensorsIcon from "@mui/icons-material/Sensors";
import SettingsIcon from "@mui/icons-material/Settings";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import WebhookIcon from "@mui/icons-material/Webhook";
import PsychologyIcon from "@mui/icons-material/Psychology";
import authProvider from "../authProvider";
import { apiUrl } from "../env";
import { Login } from "./Login";
import { lightTheme } from "../layout/themes";
import { Layout } from "../layout";
import Configuration from "../configuration/Configuration";

import { ClientList, ClientEdit, ClientCreate } from "../components/Clients";
import {
  ClassifierList,
  ClassifierEdit,
  ClassifierCreate,
} from "../components/Classifiers";
import { ProjectList, ProjectCreate, ProjectEdit } from "../components/Projects";
import { CampaignList, CampaignCreate, CampaignEdit } from "../components/Campaigns";
import { SiteList, SiteCreate, SiteEdit } from "../components/Sites";
import { AreaList, AreaCreate, AreaEdit } from "../components/Areas";
import { LocationList, LocationCreate, LocationEdit } from "../components/Locations";
import { SensorList, SensorCreate, SensorEdit } from "../components/Sensors";
import {
  MagnetoConfigList,
  MagnetoConfigCreate,
  MagnetoConfigEdit,
} from "../components/MagnetoConfigs";
import {
  ProvisionList,
  ProvisionCreate,
  ProvisionEdit,
} from "../components/Provisions";
import {
  DeploymentList,
  DeploymentCreate,
  DeploymentEdit,
} from "../components/Deployments";
import {
  MicrophoneModelList,
  MicrophoneModelCreate,
  MicrophoneModelEdit,
} from "../components/MicrophoneModels";
import {
  MicrophoneList,
  MicrophoneCreate,
  MicrophoneEdit,
} from "../components/Microphones";
import {
  ApplicationList,
  ApplicationCreate,
  ApplicationEdit,
} from "../components/Applications";
import { RouteList, RouteCreate, RouteEdit } from "../components/Routes";
import { EndpointList, EndpointCreate, EndpointEdit } from "../components/Endpoints";
import {
  AssignmentInd,
  FormatShapes,
  Mic,
  MicExternalOn,
  PinDrop,
} from "@mui/icons-material";

const dataProvider = jsonServerProvider(`${apiUrl}/api`, httpClient);

export const Admin = () => {
  return (
    <ReactAdmin
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      theme={lightTheme}
      layout={Layout}
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
      </CustomRoutes>
      <Resource
        name="sensors"
        list={SensorList}
        create={SensorCreate}
        edit={SensorEdit}
        icon={SensorsIcon}
      />
      <Resource
        name="magneto_configs"
        list={MagnetoConfigList}
        create={MagnetoConfigCreate}
        edit={MagnetoConfigEdit}
        icon={SettingsIcon}
      />
      <Resource
        name="microphones"
        list={MicrophoneList}
        create={MicrophoneCreate}
        edit={MicrophoneEdit}
        icon={MicExternalOn}
      />
      <Resource
        name="microphone_models"
        list={MicrophoneModelList}
        create={MicrophoneModelCreate}
        edit={MicrophoneModelEdit}
        icon={Mic}
      />
      <Resource
        name="clients"
        list={ClientList}
        icon={UserIcon}
        edit={ClientEdit}
        create={ClientCreate}
      />
      <Resource
        name="provisions"
        list={ProvisionList}
        create={ProvisionCreate}
        edit={ProvisionEdit}
        icon={AssignmentInd}
      />
      <Resource
        name="projects"
        list={ProjectList}
        create={ProjectCreate}
        icon={FolderIcon}
        edit={ProjectEdit}
      />
      <Resource
        name="campaigns"
        list={CampaignList}
        create={CampaignCreate}
        icon={AssignmentIcon}
        edit={CampaignEdit}
      />
      <Resource
        name="sites"
        list={SiteList}
        create={SiteCreate}
        icon={LocationCityIcon}
        edit={SiteEdit}
      />
      <Resource
        name="areas"
        list={AreaList}
        create={AreaCreate}
        edit={AreaEdit}
        icon={FormatShapes}
      />
      <Resource
        name="locations"
        list={LocationList}
        create={LocationCreate}
        icon={LocationOnIcon}
        edit={LocationEdit}
      />
      <Resource
        name="deployments"
        list={DeploymentList}
        create={DeploymentCreate}
        edit={DeploymentEdit}
        icon={PinDrop}
      />
      <Resource
        name="applications"
        list={ApplicationList}
        create={ApplicationCreate}
        edit={ApplicationEdit}
        icon={AppsIcon}
      />
      <Resource
        name="routes"
        list={RouteList}
        create={RouteCreate}
        edit={RouteEdit}
        icon={ForkRightIcon}
      />
      <Resource
        name="endpoints"
        list={EndpointList}
        create={EndpointCreate}
        edit={EndpointEdit}
        icon={WebhookIcon}
      />
      <Resource
        name="classifiers"
        list={ClassifierList}
        create={ClassifierCreate}
        edit={ClassifierEdit}
        icon={PsychologyIcon}
      />
    </ReactAdmin>
  );
};
