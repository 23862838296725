import { HttpError } from "react-admin";

// eslint-disable-next-line
export default async (url, options = {}) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: "application/json",
    });
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set("Authorization", options.user.token);
  }
  const response = await fetch(url, { ...options, headers: requestHeaders });
  const text = await response.text();
  const o = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    body: text,
  };
  let status = o.status,
    statusText = o.statusText,
    headers = o.headers,
    body = o.body;
  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deal
  }
  if (status < 200 || status >= 300) {
    let message = statusText;
    if (json && json.detail) {
      if (Array.isArray(json.detail)) {
        message = json.detail
          .map((error) => {
            if (error?.loc !== undefined) {
              return `${error.loc} : ${error?.ctx?.msg || error?.msg}`;
            } else {
              return error?.msg !== undefined ? error.msg : "Unknown error.";
            }
          })
          .join("\n");
      } else {
        message = json.detail;
      }
    }
    const promise = Promise.reject(new HttpError(message, status, json));
    return promise;
  }
  return Promise.resolve({
    status: status,
    headers: headers,
    body: body,
    json: json,
  });
};
