import * as React from "react";
import { AppBar, Logout, UserMenu, useUserMenu, AppBarProps } from "react-admin";
import { Link } from "react-router-dom";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

const ConfigurationMenu = () => {
  const { onClose } = useUserMenu();

  return (
    <MenuItem
      component={Link}
      to="/configuration"
      onClick={() => onClose()} // Close the menu
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{"Configuration"}</ListItemText>
    </MenuItem>
  );
};

const CustomUserMenu = () => (
  <UserMenu>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props: AppBarProps) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  return (
    <AppBar {...props} color="secondary" elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        id="react-admin-title"
      />
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;
