import { useState, useEffect } from "react";
import { compare } from "compare-versions";

type CacheBusterProps = {
  children: JSX.Element;
  currentVersion: string;
  loadingComponent: JSX.Element;
};

export const CacheBuster = (props: CacheBusterProps) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const metaVersion = meta.version;
        const currentVersion = props.currentVersion;

        const shouldForceRefresh = compare(metaVersion, currentVersion, ">");
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${metaVersion}. Should force refresh`);
          setIsLatestVersion(false);
          setLoading(false);
        } else {
          console.log(
            `You already have the latest version - ${metaVersion}. No cache refresh needed.`
          );
          setIsLatestVersion(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
          caches.delete(cacheName);
        }
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred while deleting the cache.");
      console.error(error);
    }
  };

  if (loading) {
    return props.loadingComponent;
  }

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();
    return null;
  }

  return props.children;
};

export default CacheBuster;
