import { Stack } from "@mui/system";
import React from "react";
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
} from "react-admin";

export const SiteForm = () => (
  <SimpleForm>
    <Stack width={{ xs: "100%", md: 300 }}>
      <TextInput source="name" validate={required()} />
      <ReferenceInput
        source="campaign_id"
        reference="campaigns"
        perPage={-1}
        sort={{ field: "name", order: "ASC" }}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Stack>
  </SimpleForm>
);
