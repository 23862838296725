import { Box, Stack } from "@mui/material";
import React from "react";

import { required, TextInput, ArrayInput, SimpleFormIterator } from "react-admin";
import { useWatch } from "react-hook-form";

export const EndpointInput = () => {
  const id = useWatch({ name: "id" });
  return (
    <>
      {id ? <TextInput disabled source="id" /> : null}
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="config.url" validate={required()} fullWidth label="URL" />
      <ArrayInput source="config.headers" label="Headers">
        <SimpleFormIterator inline disableReordering>
          <TextInput source="name" helperText={false} fullWidth />
          <TextInput source="value" helperText={false} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export const EndpointFormContent = () => {
  return (
    <Stack width="100%">
      <Box width={{ xs: "100%", md: 300 }}>
        <Stack>
          <EndpointInput />
        </Stack>
      </Box>
    </Stack>
  );
};

export default EndpointFormContent;
