import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  TextInput,
  ListViewProps,
} from "react-admin";

const campaignFilters = [<TextInput source="search_name" label="name" alwaysOn />];

export const CampaignList = (props: ListViewProps) => (
  <List filters={campaignFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="project_id" sortBy="project.name" reference="projects">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
