import React from "react";
import { Edit, SimpleForm, EditProps } from "react-admin";

import RouteFormContent from "./RouteForm";

export const RouteEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <RouteFormContent />
    </SimpleForm>
  </Edit>
);
