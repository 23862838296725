import { Stack, Box, Alert } from "@mui/material";
import React from "react";
import {
  TextInput,
  TextField,
  AutocompleteInput,
  NumberInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  Labeled,
  useRecordContext,
} from "react-admin";

export const SensorFormContent = () => {
  const record = useRecordContext();

  let menderDeviceIdElement: null | React.ReactElement = null;
  if (
    record !== undefined &&
    record["mender_device_id"] !== undefined &&
    record["mender_device_id"] !== null
  ) {
    menderDeviceIdElement = (
      <Labeled label="Mender device ID">
        <TextField source="mender_device_id" defaultValue={"Not registered"} />
      </Labeled>
    );
  } else {
    menderDeviceIdElement = (
      <Alert severity="info">
        This device is not automatically registered to mender on save. This will be
        performed on the first deployment of the magneto configuration on this device.
        However, to succeed the public key and a valid sensor ID (mender serial number)
        have to be provided.
      </Alert>
    );
  }

  return (
    <Stack width="100%">
      <Box width={{ xs: "100%", md: 300 }}>
        <Stack>
          <TextInput source="sensor_id" label="Sensor ID" validate={required()} />
          <TextInput source="public_id" label="Public ID" validate={required()} />
          <AutocompleteInput
            source="sensor_type"
            validate={required()}
            choices={[
              { id: "DEADPOOL", name: "DEADPOOL" },
              { id: "MAGNETO", name: "MAGNETO" },
              { id: "PREDICT", name: "PREDICT" },
            ]}
          />
          <NumberInput source="preamp_gain" label="Preamp gain" validate={required()} />
          <TextInput source="dev_eui" label="DevEUI (LoRa)" />
          <ReferenceArrayInput source="microphone_ids" reference="microphones">
            <SelectArrayInput optionText="name" label="Microphone channels" />
          </ReferenceArrayInput>
        </Stack>
      </Box>
      <TextInput source="pub_key" label="Public key" multiline fullWidth resettable />
      {menderDeviceIdElement}
    </Stack>
  );
};
