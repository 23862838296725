import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  TextInput,
  AutocompleteInput,
  ListViewProps,
} from "react-admin";

const microphoneFilters = [
  <TextInput source="search_name" label="name" alwaysOn />,
  <ReferenceInput
    label="Model"
    source="model_id"
    reference="microphone_models"
    alwaysOn
    allowEmpty
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

export const MicrophoneList = (props: ListViewProps) => (
  <List filters={microphoneFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField
        source="model_id"
        reference="microphone_models"
        label="Microphone model"
        sortBy="microphone_model.name"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
