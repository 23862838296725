import decodeJwt from "jwt-decode";
import { apiUrl } from "../env";

export const setTokens = (access_token, refresh_token) => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
};

export const removeTokens = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

export const getToken = () => localStorage.getItem("access_token");

export const getRefreshToken = () => localStorage.getItem("refresh_token");

const isTokenExpired = () => {
  const decoded = decodeJwt(getToken());
  const request_delay = 10;
  let expired = decoded.exp < new Date().getTime() / 1000 + request_delay;
  return expired;
};

export const refreshToken = async () => {
  if (isTokenExpired()) {
    const request = new Request(`${apiUrl}/api/auth/refresh`, {
      method: "POST",
      body: JSON.stringify({ refresh_token: getRefreshToken() }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    let data;
    const response = await fetch(request);
    if (response.ok) {
      data = await response.json();
      setTokens(data.access_token, data.refresh_token);
    }
  } else return;
};
