import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";

import {
  ButtonProps,
  HttpError,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";

const ConfigVersionCancelButton = (props: ButtonProps) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update] = useUpdate(undefined, undefined, {
    onError: (error: unknown) => {
      notify((error as HttpError)?.message, { type: "warning", undoable: false });
    },
    onSuccess: () => refresh(),
  });

  const cancelVersion = (config_id: number) => {
    update("magneto_config_versions", {
      data: { status: "canceled" },
      id: config_id,
    });
  };

  return (
    <Tooltip title="Cancel">
      <IconButton onClick={() => cancelVersion(Number(record.id))} {...props}>
        <CancelIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default ConfigVersionCancelButton;
