import { Stack } from "@mui/material";
import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  CreateProps,
} from "react-admin";

export const CampaignCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <Stack width={{ xs: "100%", md: 300 }}>
        <TextInput source="name" validate={required()} />
        <ReferenceInput
          source="project_id"
          reference="projects"
          perPage={-1}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
      </Stack>
    </SimpleForm>
  </Create>
);
