import React from "react";
import { useMediaQuery } from "@mui/material";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  SelectInput,
  ListViewProps,
  SimpleList,
} from "react-admin";

const sensorFilters = [
  <SelectInput
    source="sensor_type"
    alwaysOn
    emptyValue={""}
    choices={[
      { id: "MAGNETO", name: "MAGNETO" },
      { id: "DEADPOOL", name: "DEADPOOL" },
      { id: "PREDICT", name: "PREDICT" },
    ]}
  />,
  <TextInput source="sensor_id" label="sensor_id" />,
  <TextInput source="public_id" label="public_id" />,
];

export const SensorList = (props: ListViewProps) => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <List
      filters={sensorFilters}
      sort={{ field: "sensor_id", order: "DESC" }}
      perPage={25}
      {...props}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.sensor_id}
          secondaryText={(record) => `Public ID - ${record.public_id}`}
          tertiaryText={(record) => record.sensor_type}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="sensor_id" label="Sensor Id" />
          <TextField source="public_id" label="Public Id" />
          <TextField source="sensor_type" />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};
