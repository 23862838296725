import React from "react";
import { Edit, EditProps } from "react-admin";
import { MicrophoneForm } from "./MicrophoneForm";

export const MicrophoneEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <MicrophoneForm />
    </Edit>
  );
};
