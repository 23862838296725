import React from "react";
import { Create } from "react-admin";
import { LocationCreateForm } from "./LocationCreateForm";

export const LocationCreate = () => {
  const data = {
    name: "",
    campaign_id: "",
    area_id: "",
    site_id: "",
    position: {
      type: "Point",
      coordinates: [2.3522, 48.8566],
    },
    cartesian_coordinates: null,
    coordinates: [],
  };

  return (
    <Create record={data}>
      <LocationCreateForm />
    </Create>
  );
};
