import { useMediaQuery } from "@mui/material";
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  CloneButton,
  TextInput,
  ListViewProps,
  SimpleList,
} from "react-admin";

const magnetoConfigFilters = [<TextInput source="search_name" label="name" alwaysOn />];

export const MagnetoConfigList = (props: ListViewProps) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <List filters={magnetoConfigFilters} perPage={25} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.id}
          secondaryText={(record) => record.name}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField source="sensor_id" reference="sensors">
            <TextField source="id" />
          </ReferenceField>
          <ReferenceField source="campaign_id" reference="campaigns">
            <TextField source="id" />
          </ReferenceField>
          <EditButton />
          <CloneButton />
        </Datagrid>
      )}
    </List>
  );
};
