import React, { ChangeEvent } from "react";
import { Grid, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CardContent, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import { useInput } from "ra-core";
import { useEffect } from "react";
import { useState } from "react";

interface PolygonGridProps {
  source: string;
}

interface LatLngInputProps {
  source: string;
  index: number;
}

const LatLngInput = (props: LatLngInputProps) => {
  const coordinates_input = useInput({ source: props.source });
  const index = props.index;
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);

  useEffect(() => {
    if (coordinates_input.field.value !== null) {
      setLng(coordinates_input.field.value[index][0]);
      setLat(coordinates_input.field.value[index][1]);
    }
  }, [coordinates_input, index]);

  return (
    <span>
      <TextField
        type="number"
        label="Longitude"
        variant="standard"
        value={lng.toFixed(6)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const coordinates = coordinates_input.field.value;
          coordinates[index][0] = Number(e.target.value);
          coordinates_input.field.onChange(coordinates);
        }}
        onBlur={() => coordinates_input.field.onBlur()}
      />
      &nbsp;
      <TextField
        type="number"
        label="Latitude"
        variant="standard"
        value={lat.toFixed(6)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const coordinates = coordinates_input.field.value;
          coordinates[index][1] = Number(e.target.value);
          coordinates_input.field.onChange(coordinates);
        }}
        onBlur={() => coordinates_input.field.onBlur()}
      />
    </span>
  );
};

export const PolygonGrid = (props: PolygonGridProps) => {
  const { source } = props;

  const coordinates_input = useInput({ source });

  const removePoint = (index: number) => {
    const coordinates = [...coordinates_input.field.value];
    const second_point = coordinates[1];
    coordinates.splice(index, 1);
    // Take into account that first and last points in the polygon have to be equal to
    // for geo json compatibility.
    if (index === 0) {
      coordinates.splice(coordinates.length - 1, 1, second_point);
    }
    coordinates_input.field.onChange(coordinates);
  };

  // Add a new point as the middle of the edge between the last and first ones
  const addPoint = () => {
    const coordinates = [...coordinates_input.field.value];
    const first_point = coordinates[0];
    const last_point = coordinates[coordinates.length - 2];
    coordinates.splice(coordinates.length - 1, 0, [
      (first_point[0] + last_point[0]) / 2,
      (first_point[1] + last_point[1]) / 2,
    ]);
    coordinates_input.field.onChange(coordinates);
  };

  const coordinates = [];

  for (let point_i = 0; point_i < coordinates_input.field.value.length - 1; ++point_i) {
    const delete_point_fn = (function (i) {
      return () => removePoint(i);
    })(point_i);

    coordinates.push(
      <Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={point_i}>
        <Card variant="outlined">
          <CardHeader
            action={
              <IconButton
                aria-label="delete"
                onClick={delete_point_fn}
                disabled={coordinates_input.field.value.length <= 4 ? true : false}
              >
                <DeleteIcon />
              </IconButton>
            }
            title={`Point ${point_i + 1}`}
          />
          <CardContent>
            <LatLngInput source={source} index={point_i} />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} ref={coordinates_input.field.ref}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {coordinates}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <IconButton aria-label="add" onClick={() => addPoint()}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
