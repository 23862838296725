import * as React from "react";
import { List, Datagrid, TextField, EditButton, ListViewProps } from "react-admin";

export const ApplicationList = (props: ListViewProps) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);
