import * as React from "react";
import { useQueryClient } from "react-query";
import {
  SimpleShowLayout,
  TextField,
  ResourceContextProvider,
  Identifier,
  RaRecord,
  EditButton,
} from "react-admin";
import { Card, CardActions, CardContent } from "@mui/material";

const EndpointPreview = <RecordType extends RaRecord = any>({
  id,
  resource,
}: {
  id: Identifier;
  resource: string;
}) => {
  const queryClient = useQueryClient();
  const record = queryClient.getQueryData<RecordType>([
    resource,
    "getOne",
    { id: String(id) },
  ]);

  return (
    <ResourceContextProvider value={resource}>
      <Card sx={{ mb: 2, mt: -1 }}>
        <CardContent>
          <SimpleShowLayout record={record}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="config.url" />
          </SimpleShowLayout>
        </CardContent>
        <CardActions>
          <EditButton resource="endpoints" />
        </CardActions>
      </Card>
    </ResourceContextProvider>
  );
};

export default EndpointPreview;
