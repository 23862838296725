import { defaultTheme } from "react-admin";

// FIXME : unable to find a type accepting the RaMenuItemLink
export const lightTheme: unknown = {
  palette: {
    mode: "light",
    contrastThreshold: 2,
    primary: {
      main: "#00a6b9",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00a6b9",
      contrastText: "#ffffff",
    },
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #fff",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #00a6b9",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#ffffff",
          backgroundColor: "#00a6b9",
        },
      },
    },
  },
};

export const darkTheme: unknown = {
  palette: {
    mode: "dark",
    contrastThreshold: 2,
    primary: {
      main: "#00a6b9",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00a6b9",
      contrastText: "#ffffff",
    },
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #000",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #00a6b9",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#ffffff",
          backgroundColor: "#00a6b9",
        },
      },
    },
  },
};
