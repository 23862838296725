import React from "react";
import {
  Create,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
  required,
  CreateProps,
} from "react-admin";

export const DeploymentCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput source="azimuth" />
      <ReferenceInput
        source="location_id"
        reference="locations"
        perPage={-1}
        sort={{ field: "name", order: "ASC" }}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="sensor_id"
        reference="sensors"
        perPage={-1}
        sort={{ field: "sensor_type, sensor_id", order: "DESC, ASC" }}
        validate={required()}
      >
        <AutocompleteInput optionText="sensor_id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
