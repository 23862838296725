import React from "react";

import { Edit, SimpleForm, EditProps } from "react-admin";
import EndpointFormContent from "./EndpointForm";

export const EndpointEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <EndpointFormContent />
    </SimpleForm>
  </Edit>
);
