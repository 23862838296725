import * as React from "react";
import {
  List,
  ReferenceField,
  Datagrid,
  TextField,
  EditButton,
  ListViewProps,
} from "react-admin";

export const ClassifierList = (props: ListViewProps) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="app_id" reference="applications">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
