import React from "react";
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  NumberInput,
  TextInput,
  required,
  FileInput,
  FileField,
} from "react-admin";
import { Stack } from "@mui/system";

interface MicrophoneFormProps {
  model_id?: number | undefined;
}

export const MicrophoneForm = (props: MicrophoneFormProps) => {
  return (
    <SimpleForm>
      <Stack width={{ xs: "100%", md: 300 }}>
        <TextInput source="name" validate={required()} />
        <NumberInput source="rss_calibration" validate={required()} />
        <ReferenceInput
          source="model_id"
          reference="microphone_models"
          sort={{ field: "name", order: "ASC" }}
          validate={required()}
        >
          <SelectInput
            optionText="name"
            defaultValue={props.model_id !== undefined ? props.model_id : null}
          />
        </ReferenceInput>
        <FileInput
          source="background_noise_response"
          label="Background noise response"
          accept=".h5,.hdf5"
          multiple={false}
        >
          <FileField source="src" title="title" sx={{ mb: 5 }} />
        </FileInput>
        <FileInput
          source="relative_response"
          label="Relative response"
          accept=".h5,.hdf5"
          multiple={false}
        >
          <FileField source="src" title="title" sx={{ mb: 5 }} />
        </FileInput>
      </Stack>
    </SimpleForm>
  );
};
