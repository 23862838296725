import decodeJwt from "jwt-decode";
import { apiUrl } from "../env";

export const isAuthenticated = () => {
  const permissions = localStorage.getItem("permissions");
  if (!permissions) {
    return false;
  }
  return permissions === "user" || permissions === "wavely" ? true : false;
};

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email, password) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error("Email or password was not provided");
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append("username", email);
  formData.append("password", password);

  const request = new Request(`${apiUrl}/api/auth/token`, {
    method: "POST",
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error("Internal server error");
  }

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ("access_token" in data) {
    const decodedToken = decodeJwt(data["access_token"]);
    if (!decodedToken.groups.includes("wavely")) {
      throw new Error("Forbidden");
    }

    localStorage.setItem("access_token", data["access_token"]);
    localStorage.setItem("refresh_token", data["refresh_token"]);
  }

  return data;
};

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};
