import React from "react";
import { Create, SimpleForm, CreateProps } from "react-admin";
import { MicrophoneModelFormContent } from "./MicrophoneModelFormContent";

export const MicrophoneModelCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <MicrophoneModelFormContent />
    </SimpleForm>
  </Create>
);
