import { Drawer, Stack, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  RaRecord,
  ReferenceManyField,
  ReferenceOneField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";

const ConfigVersionShowButton = () => {
  const [showPanel, setShowPanel] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState<string>("min");

  const record = useRecordContext();

  const handleClick = () => {
    setShowPanel(true);
  };

  const handleCloseClick = () => {
    setShowPanel(false);
  };

  const handleFullScreenClick = () => {
    setDrawerHeight("max");
  };

  const handleFullScreenExitClick = () => {
    setDrawerHeight("min");
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.show">
        <VisibilityIcon />
      </Button>
      <Drawer
        anchor="bottom"
        open={showPanel}
        onClose={handleCloseClick}
        PaperProps={{ style: { height: `${drawerHeight === "min" ? "50%" : "100%"}` } }}
      >
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <CloseIcon />
          </Button>
          {drawerHeight === "min" ? (
            <Button label="Full screen" onClick={handleFullScreenClick}>
              <FullscreenIcon />
            </Button>
          ) : (
            <Button label="Exit full screen" onClick={handleFullScreenExitClick}>
              <FullscreenExitIcon />
            </Button>
          )}
        </div>
        <SimpleShowLayout record={record}>
          <TextField source="id" />
          <DateField label="Creation date" source="created_at" showTime={true} />
          <FunctionField
            label="JSON"
            source="json_config"
            render={(record: RaRecord) => (
              <TextareaAutosize
                maxRows={10}
                readOnly
                value={JSON.stringify(record.json_config, null, 2)}
                style={{ width: "100%", minWidth: "100%", maxWidth: "100%" }}
              />
            )}
          />
          <ReferenceOneField
            reference="magneto_artifacts"
            target="config_version_id"
            link={false}
            label="Artifact"
          >
            <Stack>
              <TextField source="name" />
              <Labeled label="Deployments">
                <ReferenceManyField
                  label="Deployments"
                  reference="magneto_config_deployments"
                  target="artifact_id"
                >
                  <Datagrid>
                    <DateField
                      label="Request date"
                      source="requested_at"
                      showTime={true}
                    />
                    <TextField source="status" />
                  </Datagrid>
                </ReferenceManyField>
              </Labeled>
            </Stack>
          </ReferenceOneField>
        </SimpleShowLayout>
      </Drawer>
    </>
  );
};

export default ConfigVersionShowButton;
