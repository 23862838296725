import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  CardActions,
  TextField as MuiTextField,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import React, { ChangeEvent, useState, LegacyRef, ReactElement } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PublicIcon from "@mui/icons-material/Public";
import AddIcon from "@mui/icons-material/Add";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SendIcon from "@mui/icons-material/Send";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AppsIcon from "@mui/icons-material/Apps";
import {
  ArrayInput,
  BooleanInput,
  ButtonProps,
  Datagrid,
  DateField,
  FormTab,
  NumberInput,
  PasswordInput,
  RadioButtonGroupInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  TextField,
  SelectArrayInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
  useInput,
  useRecordContext,
  FunctionField,
  RaRecord,
  useNotify,
  useCreate,
  HttpError,
  useRefresh,
  SimpleList,
  useDataProvider,
  ReferenceField,
  List,
  TopToolbar,
} from "react-admin";

import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { SelectTimezoneInput } from "../../utils/SelectTimezoneInput";
import { useForm, useFormContext, useFormState } from "react-hook-form";
import ConfigVersionShowButton from "./ConfigVersionShowButton";
import ConfigVersionCancelButton from "./ConfigVersionCancelButton";
import ConfigVersionDeployButton from "./ConfigVersionDeployButton";

const DeviceNameInput = React.forwardRef(function DeviceNameInput(
  props,
  ref: LegacyRef<HTMLDivElement>
) {
  return (
    <div {...props} ref={ref}>
      <TextInput
        source="device"
        label="Device name"
        defaultValue={"hw:0,0"}
        validate={required()}
        fullWidth={true}
      />
    </div>
  );
});

interface OnDateInputOptions {
  source: string;
  disabled: boolean;
}

const OnDateInput = (opts: OnDateInputOptions) => {
  const schedule_field = useInput({ source: opts.source });

  const [day, setDay] = useState("sunday");
  const [time, setTime] = useState("00:00");

  // parse schedule_field value
  useEffect(() => {
    if (schedule_field.field.value === null) {
      return;
    }
    const calendar_parts = schedule_field.field.value.split(/\s+/);
    if (calendar_parts.length === 2) {
      setTime(calendar_parts[1]);
    } else if (calendar_parts.length === 3) {
      setDay(calendar_parts[0]);
      setTime(calendar_parts[2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTime(e.target.value);
    schedule_field.field.onChange(`${day} *-*-* ${e.target.value}:00`);
  };

  const handleDayChange = (e: SelectChangeEvent<string>) => {
    setDay(e.target.value);
    schedule_field.field.onChange(`${e.target.value} *-*-* ${time}:00`);
  };

  return (
    <span>
      <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
        <Select
          value={day}
          onChange={handleDayChange}
          defaultValue={"sunday"}
          disabled={opts.disabled}
        >
          <MenuItem value={"sunday"}>Sunday</MenuItem>
          <MenuItem value={"monday"}>Monday</MenuItem>
          <MenuItem value={"tuesday"}>Tuesday</MenuItem>
          <MenuItem value={"wednesday"}>Wednesday</MenuItem>
          <MenuItem value={"thursday"}>Thursday</MenuItem>
          <MenuItem value={"friday"}>Friday</MenuItem>
          <MenuItem value={"saturday"}>Saturday</MenuItem>
        </Select>
      </FormControl>
      <MuiTextField
        value={time}
        onChange={handleTimeChange}
        disabled={opts.disabled}
        type="time"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // [seconds]
        }}
      />
    </span>
  );
};

interface MagnetoConfigFormProps {
  toolbar?: ReactElement | false;
  defaultValues?: any;
}

export const MagnetoConfigForm = (props: MagnetoConfigFormProps) => {
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  if (isSmallScreen) {
    return <MagnetoConfigAccordionForm {...props} />;
  } else {
    return <MagnetoConfigTabbedForm {...props} />;
  }
};

export const MagnetoConfigAccordionForm = (props: MagnetoConfigFormProps) => {
  const icon_color = "secondary";

  return (
    <SimpleForm component={Box} mb="6em" {...props}>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box mr={1}>
            <DescriptionIcon color={icon_color} />
          </Box>
          <Typography>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DescriptionForm />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box mr={1}>
            <SettingsSuggestIcon color={icon_color} />
          </Box>
          <Typography>System</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SystemForm />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box mr={1}>
            <PublicIcon color={icon_color} />
          </Box>
          <Typography>Networking</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NetworkingForm />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box mr={1}>
            <AssessmentIcon color={icon_color} />
          </Box>
          <Typography>Data Processing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataProcessingForm />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box mr={1}>
            <AppsIcon color={icon_color} />
          </Box>
          <Typography>Applications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppsForm />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box mr={1}>
            <RocketLaunchIcon color={icon_color} />
          </Box>
          <Typography>Deployment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DeploymentForm />
        </AccordionDetails>
      </Accordion>
    </SimpleForm>
  );
};

export const MagnetoConfigTabbedForm = (props: MagnetoConfigFormProps) => {
  return (
    <TabbedForm
      defaultValues={{
        campaign_id: null,
        frequency_weighting: "unweighted",
      }}
      tabs={<TabbedFormTabs variant="scrollable" />}
      {...props}
    >
      <FormTab label="Description" icon={<DescriptionIcon />}>
        <DescriptionForm />
      </FormTab>
      <FormTab label="System" icon={<SettingsSuggestIcon />}>
        <SystemForm />
      </FormTab>
      <FormTab label="Networking" icon={<PublicIcon />}>
        <NetworkingForm />
      </FormTab>
      <FormTab label="Data Processing" icon={<AssessmentIcon />}>
        <DataProcessingForm />
      </FormTab>
      <FormTab label="Applications" icon={<AppsIcon />}>
        <AppsForm />
      </FormTab>
      <FormTab label="Deployment" icon={<RocketLaunchIcon />}>
        <DeploymentForm />
      </FormTab>
    </TabbedForm>
  );
};

export const DescriptionForm = () => {
  return (
    <Box sx={{ width: { xs: "100%", md: 400 } }}>
      <Stack>
        <Typography variant="h6" gutterBottom>
          Identity
        </Typography>
        <TextInput
          source="name"
          validate={required()}
          label="Configuration name"
          resettable={true}
        />
        <Typography variant="h6" gutterBottom>
          Scope
        </Typography>
        <ReferenceInput
          source="campaign_id"
          reference="campaigns"
          label="Assigned campaign"
          allowEmpty={true}
        >
          <SelectInput optionText="name" defaultValue={undefined} emptyValue={""} />
        </ReferenceInput>
      </Stack>
    </Box>
  );
};

export const SystemForm = () => {
  const [enabledOnBootHoursReboot, setEnabledOnBootHoursReboot] = useState(false);
  const [enabledOnCalendarReboot, setEnabledOnCalendarReboot] = useState(false);
  const scheduled_reboot_on_boot_hours_input = useInput({
    defaultValue: null,
    source: "scheduled_reboot_on_boot_hours",
  });
  const scheduled_reboot_on_calendar_input = useInput({
    defaultValue: null,
    source: "scheduled_reboot_on_calendar",
  });

  useEffect(() => {
    setEnabledOnBootHoursReboot(
      scheduled_reboot_on_boot_hours_input.field.value !== null
    );
  }, [scheduled_reboot_on_boot_hours_input]);

  useEffect(() => {
    setEnabledOnCalendarReboot(scheduled_reboot_on_calendar_input.field.value !== null);
  }, [scheduled_reboot_on_calendar_input]);

  return (
    <Box sx={{ width: { xs: "100%", md: 400 } }}>
      <Stack>
        <Typography variant="h6" gutterBottom>
          Timezone
        </Typography>
        <SelectTimezoneInput source="timezone" />
        <Typography variant="h6" gutterBottom>
          Scheduled reboot
        </Typography>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setEnabledOnBootHoursReboot(e.target.checked);
                if (e.target.checked) {
                  scheduled_reboot_on_boot_hours_input.field.onChange(24);
                } else {
                  scheduled_reboot_on_boot_hours_input.field.onChange(null);
                }
              }}
              checked={enabledOnBootHoursReboot}
            />
          }
          label="Reboot after a certain amount of uptime"
        />
        <NumberInput
          label="Hours"
          source="scheduled_reboot_on_boot_hours"
          min={1}
          max={168}
          disabled={!enabledOnBootHoursReboot}
        />
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setEnabledOnCalendarReboot(e.target.checked);
                if (e.target.checked) {
                  scheduled_reboot_on_calendar_input.field.onChange(
                    "sunday *-*-* 00:00:00"
                  );
                } else {
                  scheduled_reboot_on_calendar_input.field.onChange(null);
                }
              }}
              checked={enabledOnCalendarReboot}
            />
          }
          label="Weekly reboot"
        />
        <OnDateInput
          source="scheduled_reboot_on_calendar"
          disabled={!enabledOnCalendarReboot}
        />
      </Stack>
    </Box>
  );
};

export const NetworkingForm = () => {
  const enabledEthernetInput = useInput({
    source: "network_ethernet_enabled",
    defaultValue: false,
  });

  const ethernetUseDHCPInput = useInput({
    source: "network_ethernet_dhcp",
    defaultValue: false,
  });

  const enabledGsmInput = useInput({
    source: "network_gsm_enabled",
    defaultValue: false,
  });

  const enabledLoraInput = useInput({
    source: "network_lora_enabled",
    defaultValue: false,
  });

  const enabledWifiInput = useInput({
    source: "network_wifi_enabled",
    defaultValue: false,
  });

  const enabledWifiHotspotInput = useInput({
    source: "network_wifi_hotspot_enabled",
    defaultValue: false,
  });

  return (
    <Box sx={{ width: { xs: "100%", md: 400 } }}>
      <Stack>
        <Typography variant="h6" gutterBottom>
          Sanity checks
        </Typography>
        <Typography paragraph>
          If any of the selected checks fail, then the update system revert this
          configuration and restore the previous version.
        </Typography>
        <BooleanInput
          label="Commit only if network is available"
          source="network_accessibility_check_on_update"
        />
        <BooleanInput
          label="Commit only if all remotes are reachable"
          source="network_accessibility_all_remotes"
        />
        <BooleanInput
          label="Commit only if all network interfaces are available"
          source="network_accessibility_all_interfaces"
        />
        <ArrayInput label="Remotes" source="network_accessibility_remotes">
          <SimpleFormIterator
            disableReordering={true}
            removeButton={
              <IconButton>
                <DeleteIcon />
              </IconButton>
            }
          >
            <TextInput source="address" />
          </SimpleFormIterator>
        </ArrayInput>

        <Divider />

        <Typography variant="h6" gutterBottom>
          Ethernet
        </Typography>
        <BooleanInput label="Enable interface" source="network_ethernet_enabled" />
        <BooleanInput
          label="Use DHCP"
          source="network_ethernet_dhcp"
          disabled={!enabledEthernetInput.field.value}
        />
        <TextInput
          label="Static address"
          disabled={
            ethernetUseDHCPInput.field.value || !enabledEthernetInput.field.value
          }
          validate={
            !ethernetUseDHCPInput.field.value && enabledEthernetInput.field.value
              ? required()
              : undefined
          }
          source="network_ethernet_static_address"
          defaultValue="192.168.2.10/24"
        />
        <TextInput
          label="Gateway"
          disabled={
            ethernetUseDHCPInput.field.value || !enabledEthernetInput.field.value
          }
          validate={
            !ethernetUseDHCPInput.field.value && enabledEthernetInput.field.value
              ? required()
              : undefined
          }
          source="network_ethernet_gateway"
          defaultValue="192.168.2.1"
        />
        {!ethernetUseDHCPInput.field.value && enabledEthernetInput.field.value && (
          <ArrayInput label="DNS servers" source="network_ethernet_dns_servers">
            <SimpleFormIterator
              disableReordering={true}
              removeButton={
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <TextInput source="address" />
            </SimpleFormIterator>
          </ArrayInput>
        )}

        <Divider />

        <Typography variant="h6" gutterBottom>
          GSM & LTE
        </Typography>
        <BooleanInput label="Enable interface" source="network_gsm_enabled" />
        <TextInput
          label="APN"
          source="network_gsm_apn"
          validate={enabledGsmInput.field.value ? required() : undefined}
          disabled={!enabledGsmInput.field.value}
        />

        <Divider />

        <Typography variant="h6" gutterBottom>
          LoRa
        </Typography>
        <BooleanInput label="Enable interface" source="network_lora_enabled" />
        <TextInput
          source="network_app_eui"
          fullWidth={true}
          disabled={!enabledLoraInput.field.value}
          validate={enabledLoraInput.field.value ? required() : undefined}
        />
        <TextInput
          source="network_app_key"
          fullWidth={true}
          disabled={!enabledLoraInput.field.value}
          validate={enabledLoraInput.field.value ? required() : undefined}
        />

        <Divider />

        <Typography variant="h6" gutterBottom>
          WiFi
        </Typography>

        <BooleanInput label="Enable interface" source="network_wifi_enabled" />
        <TextInput
          label="SSID"
          source="network_wifi_ssid"
          validate={enabledWifiInput.field.value ? required() : undefined}
          disabled={!enabledWifiInput.field.value}
        />
        <PasswordInput
          label="Password (WPA2)"
          source="network_wifi_password"
          validate={enabledWifiInput.field.value ? required() : undefined}
          disabled={!enabledWifiInput.field.value}
        />
        <BooleanInput
          label="Hotspot"
          source="network_wifi_hotspot_enabled"
          disabled={!enabledWifiInput.field.value}
        />
        <Typography paragraph>
          Enabling the hotspot mode transform the device as a wifi hotspot with selected
          SSID and password that can be used to access the administration web page of
          the device with the selected static ip address. In this case, the wifi client
          mode is disabled.
        </Typography>
        <TextInput
          label="Static address"
          disabled={!enabledWifiInput.field.value}
          validate={enabledWifiHotspotInput.field.value ? required() : undefined}
          source="network_wifi_static_address"
          fullWidth={true}
        />
        <TextInput
          label="Gateway"
          disabled={!enabledWifiInput.field.value}
          validate={enabledWifiHotspotInput.field.value ? required() : undefined}
          source="network_wifi_gateway"
          fullWidth={true}
        />
        {enabledWifiInput.field.value && (
          <ArrayInput label="DNS servers" source="network_wifi_dns_servers">
            <SimpleFormIterator
              disableReordering={true}
              removeButton={
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <TextInput source="address" />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </Stack>
    </Box>
  );
};

interface Classifier {
  app_id: number;
  name: string;
}

export const DataProcessingForm = () => {
  const bandFreqType = useInput({ source: "band_freq_type", defaultValue: "octave" });

  const { register } = useForm();

  const dataProvider = useDataProvider();
  const [eventClassifier, setEventClassifier] = useState<Classifier | null>(null);
  const [eventClassifiers, setEventClassifiers] = useState<any[]>([]);
  const [eventClassifierVersions, setEventClassifierVersions] = useState<any[]>([]);
  const eventClassifierVersionIdInput = useInput({
    source: "event_classifier_version_id",
    defaultValue: null,
  });

  const updateEventClassifierVersions = (newClassifier: Classifier | null) => {
    if (newClassifier === null) {
      eventClassifierVersionIdInput.field.onChange("");
      setEventClassifierVersions([]);
      eventClassifierVersionIdInput.field.onChange(null);
    } else {
      dataProvider
        .getManyReference("application_versions", {
          target: "app_id",
          id: newClassifier.app_id,
          pagination: { page: -1, perPage: -1 },
          sort: { field: "version", order: "DESC" },
          filter: {},
        })
        .then(({ data }) => {
          eventClassifierVersionIdInput.field.onChange("");
          setEventClassifierVersions(data);
          eventClassifierVersionIdInput.field.onChange(data[0]?.id);
        });
    }
  };

  useEffect(() => {
    dataProvider.getMany("classifiers", { ids: [] }).then(({ data }) => {
      setEventClassifiers(data);
    });

    if (eventClassifierVersionIdInput.field.value !== null) {
      dataProvider
        .getOne("application_versions", {
          id: eventClassifierVersionIdInput.field.value,
        })
        .then(({ data }) => {
          dataProvider
            .getManyReference("classifiers", {
              target: "app_id",
              id: data.app_id,
              pagination: { page: -1, perPage: -1 },
              sort: { field: "name", order: "DESC" },
              filter: {},
            })
            .then(({ data }) => {
              setEventClassifier(data[0]);
              updateEventClassifierVersions(data[0]);
            });
        });
    }
  }, []);

  const enabledPeriodicRecording = useInput({
    source: "save_periodic",
    defaultValue: false,
  });

  const enabledEventDetection = useInput({
    source: "event_detection_enabled",
    defaultValue: false,
  });

  const enabledFeaturesAggregation = useInput({
    source: "features_export_aggregated",
    defaultValue: false,
  });

  return (
    <Box sx={{ width: { xs: "100%", md: 400 } }}>
      <Stack>
        <Typography variant="h6" gutterBottom>
          Acquisition
        </Typography>
        <Tooltip title="ALSA device name like hw:0.0" placement="top-start">
          <DeviceNameInput />
        </Tooltip>
        <NumberInput
          source="sample_rate"
          validate={required()}
          min={0}
          step={1}
          max={192000}
          defaultValue={48000}
        />
        <NumberInput
          label="Block duration (seconds)"
          source="block_duration"
          validate={required()}
          min={0.02}
          step={0.01}
          max={1.0}
          defaultValue={0.04}
        />

        <Divider />

        <Typography variant="h6" gutterBottom>
          Features computation
        </Typography>
        <BooleanInput label="Remove DC-offset" source="remove_dc_offset" />
        <SelectArrayInput
          source="features"
          choices={[
            { id: "audiblelevel", name: "audiblelevel" },
            { id: "bandleq", name: "bandleq" },
            { id: "melspectrogram", name: "melspectrogram" },
            { id: "rms", name: "rms" },
            { id: "spectralcentroid", name: "spectralcentroid" },
            { id: "spectralcrest", name: "spectralcrest" },
            { id: "spectralspread", name: "spectralspread" },
            { id: "spl", name: "spl" },
            { id: "ultrasoundlevel", name: "ultrasoundlevel" },
          ]}
        />
        <RadioButtonGroupInput
          label="Frequency weighting"
          source="frequency_weighting"
          defaultValue="unweighted"
          choices={[
            { id: "unweighted", name: "Unweighted" },
            { id: "A", name: "A-Weighting" },
          ]}
        />
        <RadioButtonGroupInput
          label="Frequencies for per-band computations"
          source="band_freq_type"
          defaultValue="octave"
          choices={[
            { id: "octave", name: "Octave" },
            { id: "third", name: "Third octave" },
            { id: "bandwidth", name: "Fixed bandwidth" },
          ]}
          onChange={(e) => {
            bandFreqType.field.onChange(e.target.value);
          }}
        />
        <NumberInput
          label="Bandwidth (Hz)"
          source="band_freq"
          defaultValue={5000}
          disabled={bandFreqType.field.value !== "bandwidth"}
        />

        <Divider />

        <Typography variant="h6" gutterBottom>
          Recording and Storage
        </Typography>
        <BooleanInput label="Save events" source="save_events" />
        <BooleanInput label="Save continuous" source="save_continuous" />
        <Typography variant="body1" gutterBottom>
          Data retention
        </Typography>
        <NumberInput
          label="Minimal free disk %"
          source="min_free_disk_percent"
          defaultValue={10}
          max={50}
          min={10}
          step={5}
          validate={required()}
        />

        <Typography variant="body1" gutterBottom>
          Periodic recording
        </Typography>
        <BooleanInput label="Enable" source="save_periodic" />
        <NumberInput
          label="Duration (seconds)"
          source="save_periodic_duration"
          max={60}
          min={10}
          step={1}
          disabled={!enabledPeriodicRecording.field.value}
          validate={enabledPeriodicRecording.field.value ? required() : undefined}
        />
        <NumberInput
          label="Period (seconds)"
          source="save_periodic_period"
          max={3600}
          min={10}
          step={10}
          disabled={!enabledPeriodicRecording.field.value}
          validate={enabledPeriodicRecording.field.value ? required() : undefined}
        />

        <Typography variant="body1" gutterBottom>
          WAV file parameters
        </Typography>
        <BooleanInput label="Compress (lossless)" source="compress_wav_file" />
        <NumberInput
          label="Maximum file size (megabytes)"
          source="wav_max_file_size"
          max={100}
          min={10}
          step={10}
          defaultValue={100}
          validate={required()}
        />

        <Divider />

        <Typography variant="h6" gutterBottom>
          Event detection
        </Typography>
        <BooleanInput label="Enable" source="event_detection_enabled" />
        <NumberInput
          label="Buffer duration (seconds)"
          source="event_detection_buffer_duration"
          max={300}
          min={10}
          step={10}
          defaultValue={60}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Surrounding time (seconds)"
          source="event_detection_surrounding_time"
          defaultValue={1.0}
          min={0.0}
          max={10.0}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Min. inter event time (seconds)"
          source="event_detection_min_inter_event_time"
          max={60}
          min={0}
          step={1}
          defaultValue={0.0}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Min. length (seconds)"
          source="event_duration_min_length"
          defaultValue={3.0}
          max={60}
          min={1}
          step={1}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Max. length (seconds)"
          source="event_detection_max_length"
          defaultValue={60.0}
          max={300}
          min={10}
          step={1}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Min. level (dB)"
          source="event_detection_min_level"
          defaultValue={0.0}
          max={100}
          min={0}
          step={1}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Hysteresis"
          source="event_detection_hysteresis"
          defaultValue={0.0}
          max={100}
          min={0}
          step={1}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <NumberInput
          label="Integration window (seconds)"
          source="event_detection_integration_window"
          defaultValue={10.0}
          max={60}
          min={5}
          step={1}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />
        <SelectArrayInput
          source="event_detection_aggregate_features"
          label="Features aggregations"
          choices={[
            { id: "mean", name: "mean" },
            { id: "iqr", name: "interquartile range" },
            { id: "sound_level", name: "sound level" },
          ]}
          disabled={!enabledEventDetection.field.value}
          validate={enabledEventDetection.field.value ? required() : undefined}
        />

        <Autocomplete
          blurOnSelect
          options={eventClassifiers}
          value={eventClassifier}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <MuiTextField {...params} label="Classifier" />}
          onChange={(event: any, newClassifier: Classifier | null) => {
            setEventClassifier(newClassifier);
            updateEventClassifierVersions(newClassifier);
          }}
          disabled={!enabledEventDetection.field.value}
        />

        <FormControl sx={{ mt: 1 }}>
          <InputLabel>Event classifier version</InputLabel>
          <Select
            displayEmpty
            value={eventClassifierVersionIdInput.field.value}
            disabled={!enabledEventDetection.field.value || eventClassifier === null}
            {...register("event_classifier_version_id")}
          >
            {eventClassifierVersions.map((classifier_version) => (
              <MenuItem key={classifier_version.id} value={classifier_version.id}>
                {classifier_version.version}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <NumberInput
          sx={{ mt: 1 }}
          disabled={!enabledEventDetection.field.value || eventClassifier === null}
          source="class_score_threshold"
          defaultValue={0.5}
          step={0.01}
          min={0.01}
          max={0.99}
        />

        <Divider />

        <Typography variant="h6" gutterBottom>
          Features export
        </Typography>
        <SelectArrayInput
          source="features_export_selection"
          fullWidth={true}
          choices={[
            { id: "audiblelevel", name: "audiblelevel" },
            { id: "bandleq", name: "bandleq" },
            { id: "rms", name: "rms" },
            { id: "spectralcentroid", name: "spectralcentroid" },
            { id: "spectralcrest", name: "spectralcrest" },
            { id: "spectralspread", name: "spectralspread" },
            { id: "spl", name: "spl" },
            { id: "ultrasoundlevel", name: "ultrasoundlevel" },
          ]}
        />
        <BooleanInput label="Aggregate" source="features_export_aggregated" />
        <NumberInput
          label="Window duration (seconds)"
          source="features_export_aggregate_duration"
          defaultValue={10.0}
          max={600}
          min={1.0}
          step={1}
          disabled={!enabledFeaturesAggregation.field.value}
          validate={enabledFeaturesAggregation.field.value ? required() : undefined}
        />
        <SelectArrayInput
          source="features_export_aggregate_statistics"
          label="Aggregations"
          fullWidth={true}
          choices={[
            { id: "mean", name: "mean" },
            { id: "iqr", name: "interquartile range" },
            { id: "sound_level", name: "sound level" },
          ]}
          disabled={!enabledFeaturesAggregation.field.value}
          validate={enabledFeaturesAggregation.field.value ? required() : undefined}
        />
        <BooleanInput label="Save as CSV" source="features_export_csv" />
        <BooleanInput label="Save as HDF" source="features_export_hdf" />
        <BooleanInput label="Compress" source="features_export_compress" />
        <NumberInput
          label="Maximum file size (megabytes)"
          source="features_export_max_file_size"
          max={100}
          min={10}
          step={10}
          defaultValue={100}
          validate={required()}
        />
      </Stack>
    </Box>
  );
};

export const MagnetoConfigVersionShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateField label="Creation date" source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const DeploymentForm = () => {
  const { isDirty } = useFormState();

  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const notify = useNotify();
  const refresh = useRefresh();

  const [create] = useCreate(undefined, undefined, {
    onError: (error: unknown) => {
      notify((error as HttpError)?.message, { type: "warning", undoable: false });
    },
    onSuccess: () => {
      refresh();
    },
  });

  const createNewVersion = (config_id: number) => {
    create("magneto_config_versions", {
      data: { config_id: config_id },
    });
  };

  const record = useRecordContext();
  const makeNewVersionButton = (props: ButtonProps): React.ReactElement => (
    <Button
      endIcon={<SendIcon />}
      sx={{ width: "12em" }}
      onClick={() => createNewVersion(Number(record.id))}
      {...props}
    >
      New version
    </Button>
  );

  let newVersionButton = makeNewVersionButton({});

  if (record?.id === undefined || isDirty) {
    newVersionButton = (
      <Tooltip title="This config has to be saved to perform this action.">
        <span>{makeNewVersionButton({ disabled: true })}</span>
      </Tooltip>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Card sx={{ boxShadow: 0, border: 1, borderColor: "secondary.main" }}>
            <CardContent>
              <Typography>
                The current configuration can be deployed to the devices assigned to the
                corresponding scope with this panel. This required to perform a request
                that perform the following actions. First, the request is stored and
                starts in the pending state. Then, the current state of the
                configuration is stored as a new version. Finally, a deployment request
                of this version for the corresponding scope is performed.
              </Typography>
            </CardContent>
            <CardActions>{newVersionButton}</CardActions>
          </Card>
        </Stack>
        {record?.id !== undefined && (
          <ReferenceManyField
            label="Versions"
            reference="magneto_config_versions"
            target="config_id"
            sortBy="created_at"
          >
            {isSmallScreen ? (
              <SimpleList
                primaryText={(record) =>
                  `${moment(record.created_at).format("YYYY-MM-DD HH:mm")}`
                }
                secondaryText={(record) => record.status}
                // TODO: to implement this use case
                linkType={false}
              />
            ) : (
              <Datagrid empty={<Typography>No versions found</Typography>}>
                <DateField label="Creation date" source="created_at" showTime={true} />
                <FunctionField
                  label="JSON"
                  source="json_config"
                  render={(record: RaRecord) =>
                    `${JSON.stringify(record.json_config).slice(0, 20)}...`
                  }
                />
                <TextField label="Status" source="status" />
                <ConfigVersionCancelButton />
                <ConfigVersionDeployButton />
                <ConfigVersionShowButton />
              </Datagrid>
            )}
          </ReferenceManyField>
        )}
      </Stack>
    </Box>
  );
};

interface Application {
  id: number;
  name: string;
}

interface ApplicationVersion {
  id: number;
  version: string;
  app_id: number;
}

export const AppsForm = () => {
  const [openAddApplication, setOpenAddApplication] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const [applicationVersions, setApplicationVersions] = useState<ApplicationVersion[]>(
    []
  );
  const [application, setApplication] = useState("");
  const [version, setVersion] = useState("");

  const form = useFormContext();

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("applications", {
        sort: { field: "name", order: "asc" },
        pagination: { page: -1, perPage: -1 },
        filter: null,
      })
      .then(({ data }) => {
        setApplications(data);
      });
  }, []);

  const handleApplicationChange = (event: SelectChangeEvent) => {
    const app_id = event.target.value as string;
    setApplication(app_id);
    setVersion("");

    dataProvider
      .getList("application_versions", {
        sort: { field: "version", order: "asc" },
        pagination: { page: -1, perPage: -1 },
        filter: { app_id: app_id },
      })
      .then(({ data }) => {
        setApplicationVersions(data);
      });
  };

  const appChoices: JSX.Element[] = [];
  for (const app of applications) {
    appChoices.push(<MenuItem value={app.id}>{app.name}</MenuItem>);
  }

  const appVersionChoices: JSX.Element[] = [];
  for (const version of applicationVersions) {
    appVersionChoices.push(<MenuItem value={version.id}>{version.version}</MenuItem>);
  }

  const handleClickOpenAddApplication = () => {
    setOpenAddApplication(true);
  };

  const handleCloseAddApplication = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setOpenAddApplication(false);
    }
  };

  const notify = useNotify();
  const refresh = useRefresh();

  const [addApplicationVersion] = useCreate(undefined, undefined, {
    onError: (error: unknown) => {
      notify((error as HttpError)?.message, { type: "warning", undoable: false });
    },
    onSuccess: () => {
      setOpenAddApplication(false);
      refresh();
      notify("Application version added to this configuration.", {
        type: "info",
        undoable: true,
      });
    },
  });

  const handleAddApplication = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    const resource = `magneto_configs/${form.getValues()["id"]}/applications`;
    addApplicationVersion(resource, {
      data: { id: version },
    });
  };

  return (
    <Box sx={{ width: { xs: "100%" } }}>
      <Dialog
        disableEscapeKeyDown
        open={openAddApplication}
        onClose={handleCloseAddApplication}
      >
        <DialogTitle>Select an application</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl fullWidth>
              <InputLabel>Application</InputLabel>
              <Select
                value={application}
                label="Application"
                onChange={handleApplicationChange}
              >
                {appChoices}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Version</InputLabel>
              <Select
                value={version}
                label="Version"
                onChange={(e: SelectChangeEvent) =>
                  setVersion(e.target.value as string)
                }
              >
                {appVersionChoices}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddApplication}>Cancel</Button>
          <Button onClick={handleAddApplication}>Add</Button>
        </DialogActions>
      </Dialog>
      {form.getValues()["id"] !== undefined ? (
        <List
          resource={`magneto_configs/${form.getValues()["id"]}/applications`}
          title=" "
          empty={
            <Button startIcon={<AddIcon />} onClick={handleClickOpenAddApplication}>
              Add application
            </Button>
          }
          actions={
            <TopToolbar>
              <Button startIcon={<AddIcon />} onClick={handleClickOpenAddApplication}>
                Add application
              </Button>
            </TopToolbar>
          }
        >
          <Datagrid>
            <ReferenceField
              label="Application"
              reference="applications"
              source="app_id"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField label="Version" source="version" />
          </Datagrid>
        </List>
      ) : (
        <Typography>The configuration need to be saved to add applications.</Typography>
      )}
    </Box>
  );
};
