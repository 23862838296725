import { useMediaQuery, Box, Card } from "@mui/material";
import React from "react";
import { Create, CreateProps, Toolbar } from "react-admin";
import moment from "moment";

import { MagnetoConfigForm } from "./MagnetoConfigForm";

export const MagnetoConfigCreate = (props: CreateProps) => {
  // TODO: refactor style with other views
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const current_date = moment().format("MMMM Do YYYY, h:mm:ss a");

  return (
    <Create {...props} component={isSmallScreen ? Box : Card} redirect="edit">
      <MagnetoConfigForm
        defaultValues={{
          name: `New config [${current_date}]`,
        }}
        toolbar={<Toolbar />}
      />
    </Create>
  );
};
