import { Stack } from "@mui/system";
import React from "react";
import { SimpleForm, AutocompleteInput, ReferenceInput, required } from "react-admin";

export const ProvisionForm = () => (
  <SimpleForm>
    <Stack width={{ xs: "100%", md: 300 }}>
      <ReferenceInput
        source="client_id"
        reference="clients"
        perPage={-1}
        sort={{ field: "name", order: "ASC" }}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="sensor_id"
        reference="sensors"
        perPage={-1}
        sort={{ field: "sensor_type, sensor_id", order: "DESC, ASC" }}
        validate={required()}
      >
        <AutocompleteInput optionText="sensor_id" />
      </ReferenceInput>
    </Stack>
  </SimpleForm>
);
