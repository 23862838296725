import React from "react";
import { Create, SimpleForm, CreateProps } from "react-admin";
import EndpointFormContent from "./EndpointForm";

export const EndpointCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <EndpointFormContent />
    </SimpleForm>
  </Create>
);
