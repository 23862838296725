import React from "react";
import { Create, CreateProps } from "react-admin";
import { parse } from "query-string";
import { useLocation } from "react-router";
import { MicrophoneForm } from "./MicrophoneForm";

export const MicrophoneCreate = (props: CreateProps) => {
  const location = useLocation();

  const { model_id: model_id_string } = parse(location.search);
  let model_id = undefined;
  if (typeof model_id_string === "string") {
    model_id = parseInt(model_id_string, 10);
  }

  const redirect = model_id ? `/microphone_models/${model_id}/edit` : "list";

  return (
    <Create {...props} redirect={redirect}>
      <MicrophoneForm model_id={model_id} />
    </Create>
  );
};
