import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  TextInput,
  ListViewProps,
} from "react-admin";

const projectFilters = [<TextInput source="search_name" label="name" alwaysOn />];

export const ProjectList = (props: ListViewProps) => (
  <List filters={projectFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="client_id" sortBy="client.name" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
