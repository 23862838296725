import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  ListViewProps,
} from "react-admin";

const deploymentFilters = [
  <ReferenceInput
    label="Sensor Id"
    source="public_id"
    reference="sensors"
    alwaysOn
    perPage={-1}
    sort={{ field: "public_id", order: "ASC" }}
  >
    <AutocompleteInput label="Sensor public id" optionText="public_id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Location"
    source="location_id"
    reference="locations"
    alwaysOn
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

export const DeploymentList = (props: ListViewProps) => (
  <List filters={deploymentFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="azimuth" />
      <ReferenceField source="location_id" sortBy="location.name" reference="locations">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="sensor_id" sortBy="sensor.sensor_id" reference="sensors">
        <TextField source="sensor_id" />
      </ReferenceField>
    </Datagrid>
  </List>
);
