import React from "react";
import {
  Edit,
  NumberField,
  TextField,
  Datagrid,
  ReferenceManyField,
  EditButton,
  Button,
  useRecordContext,
  EditProps,
  RaRecord,
  TabbedForm,
  FormTab,
} from "react-admin";
import { Link } from "react-router-dom";
import { MicrophoneModelFormContent } from "./MicrophoneModelFormContent";

export interface MicrophoneModelRecord extends RaRecord {
  id: number;
  name: string;
}

interface AddMicrophoneButtonOptions {
  record: MicrophoneModelRecord;
}

const AddMicrophoneButton = (opts: AddMicrophoneButtonOptions) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/microphones/create",
        search: `?model_id=${opts.record.id}`,
      }}
      label="Add a microphone"
      title="Add a microphone"
    />
  );
};

export const MicrophoneModelEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <MicrophoneModelEditForm />
    </Edit>
  );
};

export const MicrophoneModelEditForm = () => {
  const record = useRecordContext<MicrophoneModelRecord>();
  return (
    <TabbedForm>
      <FormTab label="Model">
        <MicrophoneModelFormContent />
      </FormTab>
      <FormTab label="Microphones">
        <ReferenceManyField
          label="Microphones"
          reference="microphones"
          target="model_id"
          sort={{ field: "name", order: "ASC" }}
        >
          <Datagrid sx={{ width: "100%", mb: 2 }}>
            <TextField source="id" />
            <TextField source="name" />
            <NumberField source="rss_calibration" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddMicrophoneButton record={record} />
      </FormTab>
    </TabbedForm>
  );
};
