import { Stack } from "@mui/system";
import React from "react";
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from "react-admin";

export const ProjectForm = () => (
  <SimpleForm>
    <Stack width={{ xs: "100%", md: 300 }}>
      <TextInput source="name" validate={required()} />
      <ReferenceInput
        source="client_id"
        reference="clients"
        perPage={-1}
        sort={{ field: "name", order: "ASC" }}
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Stack>
  </SimpleForm>
);
