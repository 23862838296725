import React from "react";
import { Create, CreateProps } from "react-admin";

import { ProvisionForm } from "./ProvisionForm";

export const ProvisionCreate = (props: CreateProps) => (
  <Create {...props}>
    <ProvisionForm />
  </Create>
);
