import React, { useState } from "react";
import { Stack, Paper, Grid, TextField, Button } from "@mui/material";
import { Face, Fingerprint } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import { useNavigate, Navigate } from "react-router-dom";
import SvgComponent from "../utils/SvgComponent";
import { styled } from "@mui/material/styles";

import { login, isAuthenticated } from "../utils/auth";

const App = styled("div")(() => ({
  textAlign: "center",
}));

const Logo = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const Header = styled("header")(() => ({
  backgroundColor: "#777777",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
  color: "white",
}));

const LoginForm = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
}));

export const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const data = await login(email, password);

      if (data) {
        navigate("/");
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(err);
      }
    }
  };

  return isAuthenticated() ? (
    <Navigate to="/" />
  ) : (
    <App>
      <Header>
        <Logo>
          <SvgComponent />
        </Logo>
        <Paper>
          <LoginForm>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  fullWidth
                  autoFocus
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Fingerprint />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleSubmit(e);
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <br />
            <Stack container alignItems="center" justifyContent="space-between" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                sx={{ textTransform: "none" }}
              >
                Login
              </Button>
              {error && <Alert severity="error">{error}</Alert>}
            </Stack>
          </LoginForm>
        </Paper>
      </Header>
    </App>
  );
};
