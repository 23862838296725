import { IconButton, TextField as MuiTextField } from "@mui/material";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";

import {
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  useInput,
} from "react-admin";

const MultiTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiTextField-root": {
    margin: theme.spacing(),
    width: "25ch",
  },
  "& .MuiOutlinedInput-root": {
    position: "relative",
  },
  "& .MuiIconButton-root": {
    position: "absolute",
    top: 0,
    right: "3ch",
  },
}));

export const ClassifierFormContent = () => {
  const [classHierarchy, setClassHierarchy] = useState<string>();
  const classHierarchyInput = useInput({ source: "class_hierarchy" });

  useEffect(() => {
    if (
      classHierarchyInput.field.value !== "" &&
      classHierarchyInput.field.value !== null
    ) {
      setClassHierarchy(JSON.stringify(classHierarchyInput.field.value, null, 2));
    }
  }, []);

  const handleClearClick = () => {
    classHierarchyInput.field.onChange(null);
    setClassHierarchy("");
  };

  return (
    <Stack width="100%">
      <Stack>
        <Box width={{ xs: "100%", md: 300 }}>
          <TextInput source="name" validate={required()} fullWidth />
          <ReferenceInput
            source="app_id"
            reference="applications"
            perPage={-1}
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput optionText="name" validate={required()} />
          </ReferenceInput>
          <SelectInput
            fullWidth
            source="type"
            choices={[
              { id: "event", name: "Event" },
              { id: "continuous", name: "Continuous" },
            ]}
            validate={required()}
          />
        </Box>
        <MultiTextField
          label="Class hierarchy (JSON)"
          multiline
          rows={10}
          value={classHierarchy}
          onChange={(event) => {
            setClassHierarchy(event.target.value);
            classHierarchyInput.field.onChange(JSON.parse(event.target.value));
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: classHierarchy ? "visible" : "hidden" }}
                onClick={handleClearClick}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ClassifierFormContent;
