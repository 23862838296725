import React from "react";

import { Edit, SimpleForm, EditProps } from "react-admin";
import ClassifierFormContent from "./ClassifierForm";

export const ClassifierEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <ClassifierFormContent />
    </SimpleForm>
  </Edit>
);
