import React from "react";
import {
  TextInput,
  ReferenceInput,
  required,
  AutocompleteInput,
  Form,
  Toolbar,
} from "react-admin";
import { Map } from "../../utils/geo/Map";
import { Grid, Stack, CardContent } from "@mui/material";
import { PolygonGrid } from "./PolygonInput";
import { Card, CardHeader } from "@mui/material";
import { FieldValues } from "react-hook-form";

interface PolygonFormProps {
  onSubmit: (data: FieldValues) => void;
  formType: string;
  reference: string;
}

export const PolygonForm = (props: PolygonFormProps) => {
  const { onSubmit, formType, reference } = props;
  return (
    <Form onSubmit={onSubmit}>
      <CoordinatesCard formType={formType} reference={reference} />
      <Toolbar />
    </Form>
  );
};

interface CoordinatesCardProps {
  formType: string;
  reference: string;
}

function CoordinatesCard(props: CoordinatesCardProps) {
  const { formType, reference } = props;
  const source = `${reference.slice(0, -1)}_id`;

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Stack alignItems="flex-start">
            {formType === "edit" ? <TextInput disabled source="id" /> : null}
            <TextInput source="name" validate={required()} />
            <ReferenceInput
              source={source}
              reference={reference}
              perPage={-1}
              sort={{ field: "name", order: "ASC" }}
            >
              <AutocompleteInput optionText="name" validate={required()} />
            </ReferenceInput>
          </Stack>
        </Grid>
        <Grid item xs={12} md={10}>
          <Card>
            <CardHeader title="Polygon" />
            <CardContent>
              <Stack gap={2}>
                <Map source="polygon.coordinates.0" />
                <PolygonGrid source="polygon.coordinates.0" />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CardContent>
  );
}
