import React from "react";
import { List, Datagrid, TextField, ReferenceField, ListViewProps } from "react-admin";

export const RouteList = (props: ListViewProps) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="topic" />
      <ReferenceField source="endpoint_id" reference="endpoints" label="Endpoint URL">
        <TextField source="config.url" />
      </ReferenceField>
      <ReferenceField source="campaign_id" reference="campaigns" label="Campaign">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
