import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ListViewProps,
} from "react-admin";

const clientFilters = [<TextInput source="search_name" label="name" alwaysOn />];

export const ClientList = (props: ListViewProps) => (
  <List filters={clientFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);
