import React, { FC } from "react";
import { Create, SimpleForm, TextInput, required, CreateProps } from "react-admin";

export const ApplicationCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
