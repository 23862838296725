const env = process.env.REACT_APP_ENV;

let envApiUrl = "";

if (env === "production") {
  envApiUrl = process.env.REACT_APP_API_URL_PROD;
} else if (env === "staging") {
  envApiUrl = process.env.REACT_APP_API_URL_STAG;
} else {
  envApiUrl = process.env.REACT_APP_API_URL_DEV;
}

export const apiUrl = envApiUrl || "";
