import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  ListViewProps,
} from "react-admin";

const provisionFilters = [
  <ReferenceInput
    label="Client"
    source="client_id"
    reference="clients"
    alwaysOn
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Location"
    source="location_id"
    reference="locations"
    alwaysOn
    allowEmpty
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Sensor"
    source="sensor_id"
    reference="sensors"
    alwaysOn
    allowEmpty
    perPage={-1}
    sort={{ field: "public_id", order: "ASC" }}
  >
    <AutocompleteInput label="Sensor public id" optionText="public_id" />
  </ReferenceInput>,
  <SelectInput
    source="sensor_type"
    alwaysOn
    emptyValue={""}
    choices={[
      { id: "MAGNETO", name: "MAGNETO" },
      { id: "DEADPOOL", name: "DEADPOOL" },
      { id: "PREDICT", name: "PREDICT" },
    ]}
  />,
];

export const ProvisionList = (props: ListViewProps) => (
  <List filters={provisionFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="client_id" sortBy="client.name" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="sensor_id" sortBy="sensor.sensor_id" reference="sensors">
        <TextField source="sensor_id" />
      </ReferenceField>
    </Datagrid>
  </List>
);
