import React, { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";
import { SensorFormContent } from "./SensorFormContent";

export const SensorCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SensorFormContent />
    </SimpleForm>
  </Create>
);
