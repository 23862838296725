import React, { useState, useEffect, LegacyRef } from "react";
import { RaRecord, useInput } from "ra-core";
import { CheckboxGroupInput, Button } from "react-admin";

import TextField from "@mui/material/TextField";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

import {
  AutocompleteInput,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
  Toolbar,
} from "react-admin";

import { LocationMap } from "../../utils/geo/Map";

import { Card, CardActions, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import { LatLngTuple } from "leaflet";
import { FieldValues } from "react-hook-form";

function firstToUpperCase(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

interface CustomToolTipProps extends TooltipProps {
  renderTooltip: boolean;
}

const CustomTooltip = React.forwardRef(function CustomToolTip(
  props: CustomToolTipProps,
  ref: LegacyRef<HTMLDivElement>
) {
  const { renderTooltip, title, placement, children } = props;
  return renderTooltip ? (
    <Tooltip title={title} placement={placement}>
      <div ref={ref}>{children}</div>
    </Tooltip>
  ) : (
    children
  );
});

/** Custom form used to create or edit Location */
interface LocationFormProps {
  /** Custom function that handle the submission of the form */
  onSubmit: (data: FieldValues) => void;
  /** Used to know if we are in an Edit or Create form */
  formType: string;
  /** This React State is used to keep track to wich one of
  Campaing, Area or Site the current Location is linked to */
  linkedTo: string;
  /** Function used to modify the value of linkedTo */
  setLinkedTo: (data: string) => void;
  /** React admin from context */
  record: RaRecord;
}

export const LocationForm = (props: LocationFormProps) => {
  const { onSubmit, formType, linkedTo, setLinkedTo, record } = props;
  return (
    <Form onSubmit={onSubmit} record={record}>
      <CoordinatesCard
        formType={formType}
        linkedTo={linkedTo}
        setLinkedTo={setLinkedTo}
      />
      <Toolbar />
    </Form>
  );
};

interface LatLngInputProps {
  source: string;
}

const LatLngInput = (props: LatLngInputProps) => {
  const position_input = useInput({ source: props.source });
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);

  useEffect(() => {
    if (position_input.field.value !== null) {
      setLng(position_input.field.value.coordinates[0]);
      setLat(position_input.field.value.coordinates[1]);
    }
  }, [position_input]);

  return (
    <span>
      <TextField
        type="number"
        label="Longitude"
        variant="standard"
        value={lng.toFixed(6)}
        onChange={(e) =>
          position_input.field.onChange({
            type: "Point",
            coordinates: [Number(e.target.value), lat, 0],
          })
        }
        onBlur={() => position_input.field.onBlur()}
      />
      &nbsp;
      <TextField
        type="number"
        label="Latitude"
        variant="standard"
        value={lat.toFixed(6)}
        onChange={(e) =>
          position_input.field.onChange({
            type: "Point",
            coordinates: [lng, Number(e.target.value), 0],
          })
        }
        onBlur={() => position_input.field.onBlur()}
      />
    </span>
  );
};

interface GPSCoordinatesCardProps {
  source: string;
  center: LatLngTuple;
  setCenter: (data: LatLngTuple) => void;
}

function GPSCoordinatesCard(props: GPSCoordinatesCardProps) {
  const { source, center, setCenter } = props;
  const position_input = useInput({ source: source });
  return (
    <Card>
      <CardHeader title="GPS Coordinates" />
      <CardContent>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 0, sm: 2 }}
          alignItems="flex-start"
        >
          <LatLngInput source="position" />
        </Stack>
        <LocationMap source={source} center={center} />
      </CardContent>
      <CardActions>
        <Button
          size="large"
          onClick={() =>
            setCenter([
              position_input.field.value.coordinates[1],
              position_input.field.value.coordinates[0],
            ])
          }
          label="Center"
        />
      </CardActions>
    </Card>
  );
}

function CartesianCoordinatesCard() {
  return (
    <Card>
      <CardHeader title="Cartesian Coordinates" />
      <CardContent>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 0, sm: 2 }}
          alignItems="flex-start"
        >
          <NumberInput
            label="x-axis"
            source="cartesian_coordinates.coordinates[0]"
            defaultValue={0}
          />
          <NumberInput
            label="y-axis"
            source="cartesian_coordinates.coordinates[1]"
            defaultValue={0}
          />
          <NumberInput
            label="z-axis"
            source="cartesian_coordinates.coordinates[2]"
            defaultValue={0}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

interface CoordinatesCardProps {
  formType: string;
  linkedTo: string;
  setLinkedTo: (data: string) => void;
}

function CoordinatesCard(props: CoordinatesCardProps) {
  const { formType, linkedTo, setLinkedTo } = props;
  const position_input = useInput({ source: "position" });
  const lng = position_input.field.value?.coordinates[0] || 0;
  const lat = position_input.field.value?.coordinates[1] || 0;
  const [center, setCenter] = useState<LatLngTuple>([lat, lng]);

  const coordinates_input = useInput({ source: "coordinates" });

  const isDisabled = (field: string) => {
    if (linkedTo === "") return false;
    return field !== linkedTo;
  };
  /**
   * This function can be triggered by one of the 3 following field:
   * Campaign, Area or Site.
   * It will be triggered whenever the value of the field change.
   * Once triggered, it will checked which one has called her and
   * set the linkedTo state according to it.
   */
  const checkLink = (selection: string, link: string) => {
    if (selection === "") {
      setLinkedTo("");
    } else {
      if (link === "campaign") {
        setLinkedTo("campaign");
      } else if (link === "area") {
        setLinkedTo("area");
      } else if (link === "site") {
        setLinkedTo("site");
      }
    }
  };

  return (
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          <Stack alignItems="flex-start">
            {formType === "edit" ? (
              <TextInput disabled source="id" className="id" helperText={false} />
            ) : null}
            <TextInput
              source="name"
              validate={required()}
              className="name"
              helperText={false}
            />
            <CustomTooltip
              renderTooltip={linkedTo !== "campaign" && linkedTo !== ""}
              title={`This location is already linked to ${firstToUpperCase(linkedTo)}`}
              placement="top"
            >
              <ReferenceInput
                source="campaign_id"
                reference="campaigns"
                perPage={-1}
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteInput
                  helperText={false}
                  onChange={(selection) => checkLink(selection, "campaign")}
                  disabled={isDisabled("campaign")}
                  optionText="name"
                  fullWidth={true}
                />
              </ReferenceInput>
            </CustomTooltip>
            <CustomTooltip
              renderTooltip={linkedTo !== "site" && linkedTo !== ""}
              title={`This location is already linked to ${firstToUpperCase(linkedTo)}`}
              placement="top"
            >
              <ReferenceInput
                source="site_id"
                reference="sites"
                perPage={10}
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteInput
                  helperText={false}
                  onChange={(selection) => checkLink(selection, "site")}
                  disabled={isDisabled("site")}
                  optionText="name"
                  fullWidth={true}
                />
              </ReferenceInput>
            </CustomTooltip>
            <CustomTooltip
              renderTooltip={linkedTo !== "area" && linkedTo !== ""}
              title={`This location is already linked to ${firstToUpperCase(linkedTo)}`}
              placement="top"
            >
              <ReferenceInput
                source="area_id"
                reference="areas"
                perPage={-1}
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteInput
                  helperText={false}
                  onChange={(selection) => checkLink(selection, "area")}
                  disabled={isDisabled("area")}
                  optionText="name"
                  fullWidth={true}
                />
              </ReferenceInput>
            </CustomTooltip>
            <CheckboxGroupInput
              source="coordinates"
              choices={[
                { id: "gps", name: "GPS" },
                { id: "cartesian", name: "Cartesian" },
              ]}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Grid container spacing={2}>
            {coordinates_input.field.value.includes("gps") && (
              <Grid item xs={12}>
                <GPSCoordinatesCard
                  source="position"
                  center={center}
                  setCenter={setCenter}
                />
              </Grid>
            )}
            {coordinates_input.field.value.includes("cartesian") && (
              <Grid item xs={12}>
                <CartesianCoordinatesCard />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
}
