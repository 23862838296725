import React, { useCallback, useState } from "react";
import { Stack, Box } from "@mui/material";

import {
  SelectInput,
  ReferenceInput,
  required,
  useInput,
  AutocompleteInput,
} from "react-admin";

import EndPointQuickCreateButton from "./EndPointQuickCreateButton";
import { useWatch } from "react-hook-form";
import EndpointPreview from "./EndpointPreview";

interface RouteFormContentProps {
  campaign_id?: number;
}

const RouteFormContent = (props: RouteFormContentProps) => {
  const endpoint_id_input = useInput({ source: "endpoint_id" });

  const [endpointsVersion, setEndpointsVersion] = useState(0);
  const handleEndpointCreate = useCallback(
    (endpoint_id: number) => {
      endpoint_id_input.field.onChange(endpoint_id);
      setEndpointsVersion(endpointsVersion + 1);
    },
    [endpointsVersion]
  );

  const endpointId = useWatch({ name: "endpoint_id" });

  return (
    <Stack width="100%">
      <Box width={{ xs: "100%", md: 300 }}>
        <Stack>
          <ReferenceInput
            source="campaign_id"
            reference="campaigns"
            perPage={-1}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              optionText="name"
              validate={required()}
              fullWidth
              defaultValue={props.campaign_id !== undefined ? props.campaign_id : null}
            />
          </ReferenceInput>
          <>
            <ReferenceInput
              source="endpoint_id"
              reference="endpoints"
              sort={{ field: "name", order: "ASC" }}
              key={endpointsVersion}
            >
              <SelectInput
                optionText="name"
                validate={required()}
                create={<EndPointQuickCreateButton onChange={handleEndpointCreate} />}
                fullWidth
              />
            </ReferenceInput>
            {endpointId ? (
              <EndpointPreview id={endpointId} resource="endpoints" />
            ) : null}
          </>
          <AutocompleteInput
            source="topic"
            validate={required()}
            choices={[
              { id: "FEATURES", name: "Features" },
              { id: "EVENT", name: "Event" },
            ]}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default RouteFormContent;
