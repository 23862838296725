import { Grid, Stack, Box, Card, useMediaQuery } from "@mui/material";
import React from "react";
import {
  CloneButton,
  Edit,
  EditProps,
  SaveButton,
  DeleteButton,
  Toolbar,
} from "react-admin";
import { MagnetoConfigForm } from "./MagnetoConfigForm";

export const CloneableToolbar = () => (
  <Toolbar>
    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item>
        <SaveButton label="Save" />
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={2}>
          <CloneButton label="Clone" />
          <DeleteButton label="Delete" />
        </Stack>
      </Grid>
    </Grid>
  </Toolbar>
);

export const MagnetoConfigEdit = (props: EditProps) => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <Edit {...props} component={isSmallScreen ? Box : Card} redirect={false}>
      <MagnetoConfigForm toolbar={<CloneableToolbar />} />
    </Edit>
  );
};
