import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useTheme, Title } from "react-admin";

import { darkTheme, lightTheme } from "../layout/themes";
import { ThemeOptions, Typography } from "@mui/material";

const Configuration = () => {
  const [theme, setTheme] = useTheme();

  return (
    <Card sx={{ mt: 2 }}>
      <Title title={"Configuration"} />
      <CardContent>
        <Typography>Theme</Typography>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          disabled={theme?.palette?.mode === "light"}
          onClick={() => setTheme(lightTheme as ThemeOptions)}
        >
          Light
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          disabled={theme?.palette?.mode === "dark"}
          onClick={() => setTheme(darkTheme as ThemeOptions)}
        >
          Dark
        </Button>
      </CardContent>
    </Card>
  );
};

export default Configuration;
