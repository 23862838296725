import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  TextInput,
  ListViewProps,
} from "react-admin";

const locationFilters = [
  <TextInput source="search_name" label="name" alwaysOn />,
  <ReferenceInput
    label="Campaign"
    source="campaign_id"
    reference="campaigns"
    alwaysOn
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Site"
    source="site_id"
    reference="sites"
    alwaysOn
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Area"
    source="area_id"
    reference="areas"
    alwaysOn
    perPage={-1}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

export const LocationList = (props: ListViewProps) => (
  <List filters={locationFilters} {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="campaign_id" sortBy="campaign.name" reference="campaigns">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="site_id" sortBy="site.name" reference="sites">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="area_id" sortBy="area.name" reference="areas">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
