import React from "react";
import { Admin } from "./views/Admin";
import CacheBuster from "./CacheBuster";

import packageJson from "../package.json";

const App = () => {
  return (
    <CacheBuster currentVersion={packageJson.version} loadingComponent={null}>
      <Admin />
    </CacheBuster>
  );
};

export default App;
