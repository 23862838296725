import React, { useCallback, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  TextField,
  Datagrid,
  ReferenceManyField,
  Button,
  useRecordContext,
  EditProps,
  RaRecord,
  Create,
  Form,
  useCreate,
  useNotify,
  SaveButton,
  HttpError,
} from "react-admin";
import { FieldValues } from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";

export interface ApplicationRecord extends RaRecord {
  id: number;
  name: string;
}

interface CreateVersionButtonOptions {
  record: ApplicationRecord;
  onChange: () => void;
}

function CreateVersionButton(props: CreateVersionButtonOptions) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = (data: FieldValues) => {
    data.app_id = props.record.id;

    create(
      "application_versions",
      { data },
      {
        onSuccess: () => {
          props.onChange();
        },
        onError: (error: unknown) => {
          notify((error as HttpError)?.message, { type: "warning", undoable: false });
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create version"
      >
        <DialogTitle>Create version</DialogTitle>
        <Create redirect={`/applications/${props.record.id}/edit`}>
          <Form resource="application_versions" onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <TextInput source="version" validate={required()} />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={isLoading}
                >
                  <CancelIcon />
                </Button>
                <SaveButton disabled={isLoading} />
              </DialogActions>
            </>
          </Form>
        </Create>
      </Dialog>
    </>
  );
}

export const ApplicationEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <ApplicationEditForm />
    </Edit>
  );
};

export const ApplicationEditForm = () => {
  const record = useRecordContext<ApplicationRecord>();
  const [formVersion, setFormVersion] = useState(0);
  const handleChange = useCallback(
    () => setFormVersion(formVersion + 1),
    [formVersion]
  );

  return (
    <SimpleForm>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={2}>
          <Stack alignItems="flex-start">
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={10}>
          {/* ensure to refresh this form when the create version is used */}
          <Card key={formVersion} style={{ height: "100%" }}>
            <CardHeader title="Versions" />
            <CardContent>
              <ReferenceManyField
                label="Versions"
                reference="application_versions"
                target="app_id"
                sort={{ field: "version", order: "ASC" }}
              >
                <Datagrid contentEditable={false}>
                  <TextField source="id" />
                  <TextField source="version" />
                </Datagrid>
              </ReferenceManyField>
              <CreateVersionButton record={record} onChange={handleChange} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
