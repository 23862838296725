import {
  LatLngBounds,
  LeafletEvent,
  LatLngExpression,
  LatLngLiteral,
  LatLng,
  LatLngTuple,
} from "leaflet";
import { useInput } from "ra-core";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Polygon,
  Tooltip,
} from "react-leaflet";

import "./map.css";

interface BoundsProps {
  coordinates: LatLngBounds;
}

function Bounds(props: BoundsProps) {
  const { coordinates } = props;
  const map = useMap();

  useEffect(() => {
    map.fitBounds(coordinates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
}

interface MapProps {
  source: string;
}

interface MarkerContentProps {
  position: LatLngLiteral;
  draggable: boolean;
  onDrag: (data: LatLng, index: number) => void;
  index: number;
}

const MarkerContent = (props: MarkerContentProps) => {
  const { position, draggable, onDrag, index } = props;

  return (
    <Marker
      position={position}
      draggable={draggable}
      eventHandlers={{
        drag: (e: LeafletEvent) => {
          const position = e.target.getLatLng();
          return onDrag(position, index);
        },
      }}
    >
      <Tooltip>
        Point {index + 1} at ({position.lng.toFixed(6)}, {position.lat.toFixed(6)})
      </Tooltip>
    </Marker>
  );
};

export function Map(props: MapProps) {
  const { source } = props;
  const input = useInput({ source });
  const coordinates = input.field.value.map((item: Array<number>): LatLngTuple => {
    return [item[1], item[0]];
  });

  const eventHandlers = (position: LatLng, index: number) => {
    const coordinates = [...input.field.value];
    coordinates[index] = [position.lng, position.lat];
    if (index === 0) {
      coordinates[coordinates.length - 1] = [position.lng, position.lat];
    }
    input.field.onChange(coordinates);
  };

  return (
    <MapContainer scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Bounds coordinates={new LatLngBounds(coordinates)} />
      {input.field.value
        .slice(0, input.field.value.length - 1)
        .map(function (item: Array<number>, index: number) {
          return (
            <MarkerContent
              key={index}
              position={{ lat: item[1], lng: item[0] }}
              draggable={true}
              onDrag={eventHandlers}
              index={index}
            />
          );
        })}
      elements.push(
      <Polygon
        pathOptions={input.field.value ? { color: "blue" } : { color: "transparent" }}
        positions={
          input.field.value
            ? input.field.value.map((item: Array<number>) => [item[1], item[0]])
            : coordinates
        }
      />
      )
    </MapContainer>
  );
}

interface LocationMapContentProps {
  source: string;
  center: LatLngExpression;
}

function LocationMapContent(props: LocationMapContentProps) {
  const { source, center } = props;
  const input = useInput({ source: source });

  const map = useMap();

  const [lng, setLng] = useState(input.field.value.coordinates[0]);
  const [lat, setLat] = useState(input.field.value.coordinates[1]);

  const onDragMarker = (e: LeafletEvent) => {
    const position = e.target.getLatLng();
    input.field.onChange({
      type: "Point",
      coordinates: [position.lng, position.lat, 0],
    });
    input.field.onBlur();
    setLng(position.lng);
    setLat(position.lat);
  };

  useEffect(() => {
    setLng(input.field.value.coordinates[0]);
    setLat(input.field.value.coordinates[1]);
  }, [input]);

  useEffect(() => {
    map.setView(center, map.getZoom());
  }, [center]);

  return (
    <div>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={[lat, lng]}
        draggable={true}
        eventHandlers={{ drag: (e: LeafletEvent) => onDragMarker(e) }}
      >
        <Tooltip>
          Point at ({lng.toFixed(6)}, {lat.toFixed(6)})
        </Tooltip>
      </Marker>
    </div>
  );
}

interface LocationMapProps {
  source: string;
  center: LatLngTuple;
}

export function LocationMap(props: LocationMapProps) {
  const { source, center } = props;
  const position_input = useInput({ source: source });

  if (position_input.field.value !== null) {
    return (
      <MapContainer scrollWheelZoom={true} zoom={13} center={center}>
        <LocationMapContent source={source} center={center}></LocationMapContent>
      </MapContainer>
    );
  } else {
    return null;
  }
}
