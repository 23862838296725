import React, { useCallback } from "react";
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  Form,
  HttpError,
  useCreateSuggestionContext,
} from "react-admin";
import { DialogActions } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { FieldValues } from "react-hook-form";
import { EndpointInput } from "../Endpoints/EndpointForm";

interface EndPointQuickCreateButtonProps {
  onChange: (endpoint_id: number) => void;
}

function EndPointQuickCreateButton(props: EndPointQuickCreateButtonProps) {
  const [create] = useCreate();
  const notify = useNotify();

  const { onCancel, onCreate } = useCreateSuggestionContext();

  const handleSave = useCallback(
    (values: FieldValues) => {
      create(
        "endpoints",
        { data: values },
        {
          onSuccess: (data) => {
            props.onChange(data.id);
            onCreate(data);
          },
          onError: (error: unknown) => {
            notify((error as HttpError)?.message, { type: "warning", undoable: false });
          },
        }
      );
    },
    [create, notify, onCreate]
  );

  return (
    <Dialog fullWidth open onClose={onCancel} aria-label="Create endpoint">
      <DialogTitle>Create endpoint</DialogTitle>

      <Form resource="endpoints" onSubmit={handleSave}>
        <>
          <DialogContent>
            <EndpointInput />
          </DialogContent>
          <DialogActions>
            <SaveButton />
            <Button label="ra.action.cancel" onClick={onCancel}>
              <CancelIcon />
            </Button>
          </DialogActions>
        </>
      </Form>
    </Dialog>
  );
}

export default EndPointQuickCreateButton;
