import decodeJwt from "jwt-decode";
import { setTokens, removeTokens } from "./utils/token";
import { apiUrl } from "./env";

const authProvider = {
  login: ({ username, password }) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const request = new Request(`${apiUrl}/api/auth/token`, {
      method: "POST",
      body: formData,
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token, refresh_token }) => {
        const decodedToken = decodeJwt(access_token);
        if (!decodedToken.groups.includes("wavely")) {
          throw new Error("Forbidden");
        }
        setTokens(access_token, refresh_token);
      });
  },
  logout: () => {
    removeTokens();
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      removeTokens();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("access_token") ? Promise.resolve() : Promise.reject(),

  getPermissions: () => Promise.resolve(),
};

export default authProvider;
