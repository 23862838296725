import React from "react";
import { Create } from "react-admin";
import { AreaCreateForm } from "./AreaCreateForm";

export const AreaCreate = () => {
  const data = {
    name: "",
    site_id: 0,
    id: 0,
    polygon: {
      type: "Polygon",
      coordinates: [
        [
          [2.3522219, 48.856614],
          [4.35171, 50.85034],
          [-0.1277583, 51.5073509],
          [2.3522219, 48.856614],
        ],
      ],
    },
  };

  return (
    <Create record={data}>
      <AreaCreateForm endpoint="areas" reference="sites" />
    </Create>
  );
};
