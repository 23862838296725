import React from "react";
import { useNotify, useRedirect, useUpdate, HttpError } from "ra-core";
import { useParams } from "react-router-dom";
import { PolygonForm } from "./PolygonForm";
import { FieldValues } from "react-hook-form";

interface AreaEditFormProps {
  endpoint: string;
  reference: string;
}

export const AreaEditForm = (props: AreaEditFormProps) => {
  const { endpoint, reference } = props;
  const basePath = `/${endpoint}`;
  const { id } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();
  const onSubmit = (data: FieldValues) => {
    data.polygon.coordinates[0] = data.polygon.coordinates[0].map(
      (item: Array<number>) => [item[0], item[1]]
    );
    update(
      endpoint,
      { id, data },
      {
        onSuccess: () => {
          notify("Element updated", { type: "info", undoable: true });
          redirect(basePath);
        },
        onError: (error: unknown) => {
          if ((error as HttpError)?.message.includes("Polygon must be convex")) {
            data.polygon.coordinates[0].pop();
          }
          notify((error as HttpError)?.message, { type: "warning", undoable: false });
        },
      }
    );
  };

  return <PolygonForm reference={reference} onSubmit={onSubmit} formType="edit" />;
};
