import fetchJson from "./fetchJson";
import { getToken, refreshToken } from "../utils/token";

const httpClient = async (url, options) => {
  if (!options) {
    options = {};
  }

  await refreshToken();

  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    });
  }
  return fetchJson(url, options);
};

export default httpClient;
