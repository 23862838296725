import React from "react";
import AddIcon from "@mui/icons-material/Add";

import {
  Edit,
  TextInput,
  required,
  TextField,
  Datagrid,
  ReferenceManyField,
  EditButton,
  Button,
  useRecordContext,
  EditProps,
  ReferenceInput,
  SelectInput,
  RaRecord,
  ReferenceField,
  FormTab,
  TabbedForm,
} from "react-admin";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";

export const CampaignEdit = (props: EditProps) => (
  <Edit {...props}>
    <CampaignEditForm />
  </Edit>
);

export interface CampaignModelRecord extends RaRecord {
  id: number;
  name: string;
  project_id: number;
}

interface AddRouteButtonOptions {
  record: CampaignModelRecord;
}

const AddRouteButton = (opts: AddRouteButtonOptions) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/routes/create",
        search: `?campaign_id=${opts.record.id}`,
      }}
      label="Add a route"
      title="Add a route endpoint"
      startIcon={<AddIcon />}
    />
  );
};

export const CampaignEditForm = () => {
  const record = useRecordContext<CampaignModelRecord>();
  return (
    <TabbedForm>
      <FormTab label="Identity">
        <Stack width={{ xs: "100%", md: 300 }}>
          <TextInput disabled source="id" fullWidth />
          <TextInput source="name" validate={required()} fullWidth />
          <ReferenceInput
            source="project_id"
            reference="projects"
            perPage={-1}
            sort={{ field: "name", order: "ASC" }}
            validate={required()}
          >
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Stack>
      </FormTab>
      <FormTab label="Routes">
        <ReferenceManyField label="Route" reference="routes" target="campaign_id">
          <Datagrid sx={{ width: "100%", mb: 2 }}>
            <ReferenceField source="endpoint_id" reference="endpoints" label="Name">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="endpoint_id" reference="endpoints" label="URL">
              <TextField source="config.url" />
            </ReferenceField>
            <TextField source="topic" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddRouteButton record={record} />
      </FormTab>
    </TabbedForm>
  );
};
